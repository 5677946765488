import { TeacherDto } from "../planning/TeacherDto";

type TeacherCriteriaModel = {
  departments?: number[] | undefined;
  educationalDirections?: number[] | undefined;
  schoolSubjects?: number[] | undefined;
}

const defaultTeacherCriteriaModel = {
  departments: undefined,
  educationalDirections: undefined,
  schoolSubjects: undefined
} as TeacherCriteriaModel;

function copy<T>(model: T) {
  return JSON.parse(JSON.stringify(model)) as T;
}

const teacherFilter = (collection: TeacherDto[] | undefined, criteriaModel:TeacherCriteriaModel) => {
  // console.log(
  //   copy(collection),
  //   copy(criteriaModel)
  // );
  
  if (!collection)
    return [];

    try {
      return collection.filter((q: TeacherDto) => {
        let keep = true;
        if (criteriaModel.departments) {
          const intersect = (q.teacherTerms ?? []).map(w => w.relDepartment).filter(w => criteriaModel.departments.includes(w));
          keep &&= intersect.length > 0;
        }
        debugger;
        if ((criteriaModel.educationalDirections?.length ?? 0) === 1 && (criteriaModel.schoolSubjects?.length ?? 0) === 1) {
          const intersect = q.teacherSubjects.filter(w => w.relEducationalDirection === criteriaModel.educationalDirections[0] && w.relSchoolSubject === criteriaModel.schoolSubjects[0]);
          keep &&= intersect.length > 0;
        }
        else {
          if (criteriaModel.educationalDirections) {
            const educationalDirections = [];
            (q.teacherSubjects ?? []).map(w => w.relEducationalDirection).forEach(w => educationalDirections.push(w));
            //(q.teacherTerms ?? []).map(w => w.relEducationalDirection).forEach(w => educationalDirections.push(w));
        
            const intersect = educationalDirections.filter(w => criteriaModel.educationalDirections.includes(w));
            keep &&= intersect.length > 0;
          }
          if (criteriaModel.schoolSubjects) {
            const intersect = (q.teacherSubjects ?? []).map(w => w.relSchoolSubject).filter(w => criteriaModel.schoolSubjects.includes(w)) ?? [];
            keep &&= intersect.length > 0;
          }
        }
        return keep;
      });
    } catch(e) {
      console.log(collection[0], collection.map(t => t.teacherTerms), collection.flatMap(t => t.teacherTerms));
      
      console.log(JSON.parse(JSON.stringify(collection)));
      throw e;
    }
}

export {
  TeacherCriteriaModel,
  teacherFilter,
  defaultTeacherCriteriaModel
}
