import Repository from '@/repositories/RepositoryFactory';
import ENUM from '../../enums/APISTATE';
import store from '../';

const AppUsersRepository = Repository.get('appUsers');

const byInitials = (a,b) => {
  const aValue = `${a.initials}`.toUpperCase();
  const bValue = `${b.initials}`.toUpperCase();
  return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
};

export default {
  namespaced: true,
  state: {
    apiState: ENUM.INIT,
    appUser: {},
    appUsers: [],
    myAppUser: {}
  },
  mutations: {
    setApiState: (state, apiState) => {
      state.apiState = apiState;
    },
    setAppUser(state, appUserResponse) {
      const { data } = appUserResponse;
      state.appUser = data;
      state.apiState = ENUM.LOADED;
    },
    setAppUsers(state, appUsersResponse) {
      const { data } = appUsersResponse;
      state.appUsers = data;
      state.apiState = ENUM.LOADED;
    },
    setMyAppUser(state, appUserResponse) {
      const { data } = appUserResponse;
      state.myAppUser = data;
      state.apiState = ENUM.LOADED;
    }
  },
  actions: {
    async loadAppUser({ commit }, appUserId) {
      commit('setApiState', ENUM.LOADING);
      try {
        if (appUserId !== null) {
          commit('setAppUser', await AppUsersRepository.getOne(appUserId));
        }
        else {
          commit('setAppUser', {data: {}});
        }
      }
      catch (error) {
        console.error(error);
        commit('setApiState', ENUM.ERROR);
      }
    },
    async loadAppUsers({ commit }) {
      commit('setApiState', ENUM.LOADING);
      try {
        commit('setAppUsers', await AppUsersRepository.getAll());
      }
      catch (error) {
        console.error(error);
        commit('setApiState', ENUM.ERROR);
      }
    },
    async registerAppUser({ commit, dispatch }, appUserDto) {
      commit('setApiState', ENUM.LOADING);
      try {
        await AppUsersRepository.registerAppUser(appUserDto);
        await dispatch('loadAppUsers');
      }
      catch (error) {
        console.error(error);
        commit('setApiState', ENUM.ERROR);
      }
    },
    async updateAppUser({ commit, dispatch, state }, appUserDto) {
      commit('setApiState', ENUM.LOADING);
      try {
        await AppUsersRepository.updateAppUser(appUserDto);
        await dispatch('loadAppUsers');
        if (appUserDto.id === state.appUser?.id) {
          await dispatch('loadAppUser', appUserDto.id);
        }
      }
      catch (error) {
        console.error(error);
        commit('setApiState', ENUM.ERROR);
      }
    },
    async deleteAppUser({commit, dispatch}, userId) {
      commit('setApiState', ENUM.LOADING);
      try {
        await AppUsersRepository.deleteAppUser(userId);
        await dispatch('loadAppUsers');
      }
      catch (error) {
        console.error(error);
        commit('setApiState', ENUM.ERROR);
      }
    },
    async setAppUserPassword({ commit }, { id, form }) {
      commit('setApiState', ENUM.LOADING);
      try {
        await AppUsersRepository.setAppUserPassword(id, form);
        commit('setApiState', ENUM.LOADED);
      }
      catch (error) {
        console.error(error);
        commit('setApiState', ENUM.ERROR);
      }
    },
    async loadMyUser({ commit }) {
      commit('setApiState', ENUM.LOADING);
      try {
        const oidcUser = store?.getters['oidcStore/oidcUser'];
        commit('setMyAppUser', await AppUsersRepository.getOne(oidcUser?.sub));
      }
      catch (error) {
        console.error(error);
        commit('setApiState', ENUM.ERROR);
      }
    },
    async passwordReset({commit}, email) {
      commit('setApiState', ENUM.LOADING);
      try {
        await AppUsersRepository.passwordReset(email);
        commit('setApiState', ENUM.LOADED);
      }
      catch (error) {
        console.error(error);
        commit('setApiState', ENUM.ERROR);
        throw error;
      }
    }
  },
  getters: {
    appUserApiState(state) {
      return state.apiState;
    },
    appUser(state) {
      return state.appUser;
    },
    appUsers(state) {
      return state.appUsers.sort(byInitials);
    },
    myAppUser(state) {
      return state.myAppUser;
    }
  }
}