<template>
  <section class="tab-component mb-7">
    <div class="tab-component__left-controls">
      <slot>
      </slot>
    </div>
    <div class="tab-component__right-controls">
      <slot name="taboverride">
        <div class="tab-component__tabs">
          <router-link v-for="(tab, index) in tabs"
                       :key="index"
                       :to="tab.to"
                       custom
                       v-slot="{ navigate, href, isExactActive }">
            <button v-if="tab.disabled"
                    :disabled="true"
                    :class="['tab-component__tab', 'tab-component__tab--disabled']"
                    @click="navigate">
              {{ tab.title }}
            </button>
            <a v-else
               :class="['tab-component__tab', isExactActive && 'router-link-exact-active']"
               :href="href"
               @click="navigate">
              {{ tab.title }}
            </a>
          </router-link>
        </div>
      </slot>
    </div>
  </section>
</template>

<script>
export default {
  name: "tab-component",
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
.tab-component {
  border-bottom: 1px solid $tab-border-color;
  height: 52px;
  padding: 10px 7px 0 7px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 10px;

  .tab-component__left-controls {
    display: flex;
    flex-flow: row nowrap;
    gap: 15px;

    .multiselect {
      min-width: 170px;
    }
  }
  .tab-component__right-controls {
    display: flex;
    flex-flow: row nowrap;
    gap: 15px;
  }
  .tab-component__tabs {
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    gap: 5px;
    font-size: 14px;
    font-weight: 700;

    .tab-component__tab {
      border: 1px solid $tab-border-color;
      background-color: $tab-border-color;
      color: $tab-inactive-color;
      padding: 0px 10px;
      position: relative;
      top: 1px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      display: flex;
      align-items: center;

      &.router-link-exact-active {
        border-bottom: 1px solid $page-background-color;
        background-color: $tab-active-background-color;
        color: $tab-active-color;
      }
      &.tab-component__tab--disabled {
        color: #ccc;
        background-color: #eee;
      }
      &.tab-component__tab--disabled::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        backdrop-filter: blur(1px);
        content: " ";
        cursor: default;
      }
    }
  }
}
</style>
