import Repository from "@/repositories/RepositoryFactory";
import LoadingState from "../../enums/APISTATE";

const departmentsRepo = Repository.get("departments");

const byName = (a, b) => {
  const aValue = `${a.name}`.toUpperCase();
  const bValue  = `${b.name}`.toUpperCase();
  return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
};

export default {
  namespaced: true,
  state: {
    state: LoadingState.INIT,
    departments: []
  },
  mutations: {
    setState: (state, loadingState) => {
      state.state = loadingState;
    },
    setDepartments: (state, departments) => {
      state.departments = departments;
      state.state = LoadingState.LOADED;
    },
  },
  actions: {
    async loadDepartments({ commit, state }, appUserId) {
      if (state?.departments?.length !== 0)
        return;

      commit("setState", LoadingState.LOADING);
      try {
        const departmentsResponse = await departmentsRepo.getAll(appUserId) 
        commit("setDepartments", departmentsResponse.data);
      }
      catch (error) {
        console.error(error);
        commit("setState", LoadingState.ERROR);
      }
    }

  },
  getters: {
    state(state) {
      return state.state;
    },
    departments(state) {
      return state.departments.sort(byName);
    },
    isLoading(state) {
      return state.state === LoadingState.LOADING;
    },
    isDepartmentRemote: state => (departmentId) => state.departments.find(q => q.id === departmentId)?.remote ?? false,
    
  }
}