export const classSubjects = [
  {
    path: '/classSubjects',
    name: 'ClassSubjects',
    component: () => import('@/views/classsubjects/ClassSubjects.vue')
  },
  {
    path: '/classSubject/new',
    name: 'NewClassSubject',
    component: () => import('@/views/classsubjects/ClassSubject.vue')
  },
  {
    path: '/classSubject/studentAmounts',
    props: true,
    component: () => import('@/views/classsubjects/tabs/ClassSubjectNumberPrStudent.vue')
  },
  {
    path: '/classSubject/:classSubjectId',
    props: true,
    component: () => import('@/views/classsubjects/ClassSubject.vue')
  },
  {
    path: '/classSubject/:classSubjectId/studentTypes',
    props: true,
    component: () => import('@/views/classsubjects/tabs/ClassSubjectStudentTypes.vue')
  }
];
