import type { GetterTree, MutationTree, ActionTree } from 'vuex';
import Vue from 'vue';

type Check = {
  key: string,
  validation: Function,
  message: string,
  active: Function|undefined,
}
type Result = {
  key: string,
  message: string
}

// const localeParseFloat = (s:string, locale:any) => {
//   const [_,thousandsSeparator,__,___,____,decimalSeparator] = 1111.1.toLocaleString(locale);
//   s = Array.from(s, c => c === thousandsSeparator ? ""
//                        : c === decimalSeparator   ? "." : c).join("")
//   return parseFloat(s);
// }

class State {
  checks: Check[] = [];
  results: Result[] = [];
}

const mutations = <MutationTree<State>> {
  setChecks(state, checks:Check[]) {
    Vue.set(state, "checks", checks);
  }
};

const actions = <ActionTree<State, any>> {
  assignCriteria({commit}, checks:Check[]) {
    commit('setChecks', checks);
  },
  validate({state, dispatch}) {
    dispatch('clearErrors');
    
    let final = true;
    for (const v of state.checks) {
      if (v.active) {
        if (!v.active()) continue;
      }
      const check = v.validation();
      if (!check) {
        state.results.push({ key: v.key, message: v.message });
        final &&= check;
      }
    }
    return final;
  },
  clearErrors({state}) {
    Vue.set(state, "results", []);
  }

};

const getters = <GetterTree<State, any>> {
  isValid: state => (key:string) => !state.results.some(q => q.key === key),
  validationMessage: state => (key:string) => state.results.find(q => q.key === key)?.message ?? undefined,

  criteriaRequired: () => (key:string, property:Function) => ({
    key,
    validation: () => property() !== undefined && `${property()}`.length > 0,
    message: 'Skal udfyldes'
  }),

  criteriaEmail: () => (key:string, property:Function) => ({
    key,
    validation: () => `${property()}`.match(/[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+/),
    message: 'Angiv venligst en gyldig e-mail adresse'
  }),

  criteriaLength: () => (key:string, property:Function, min:number, max:number) => ({
    key,
    validation: () => { const length = `${property()}`.length; return min <= length && length <= max; },
    message: `Skal indeholde mellem ${min} og ${max} tegn`
  }),

  criteriaValue: () => (key:string, property:Function, min:number|undefined, max:number|undefined) => ({
    key,
    validation: () => (min !== undefined && max !== undefined) ? (parseFloat(property()) >= min && parseFloat(property()) <= max)
                    : (min !== undefined)                      ? (parseFloat(property()) >= min)
                                                               : (parseFloat(property()) >= max),
    message: (min !== undefined && max !== undefined) ? `Skal være en værdi mellem ${min} og ${max}`
           : (min !== undefined)                      ? `Skal være en værdi på mindst ${min}`
                                                      : `Skal være en værdi på højst ${max}`
  }),
};

const ValidationStore = {
  namespaced: true,
  state: new State(),
  mutations,
  actions,
  getters
};

export default ValidationStore;
