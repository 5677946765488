






























import Vue from "vue"

export default Vue.extend({
  name: "Foldable",
  props: {
    draggable: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isOpen: false
  }),
})
