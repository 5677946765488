import type { GetterTree, MutationTree, ActionTree } from 'vuex';
import APISTATE from '@/enums/APISTATE';
import Vue from 'vue';
import { TeacherOnProjectAllocationDtoResult } from '@/models/planning/TeacherOnProjectAllocationDtoResult';
import { TeacherOnSubjectAllocationDtoResult } from '@/models/planning/TeacherOnSubjectAllocationDtoResult';
import { TeacherOnProjectAllocationRepository } from '@/repositories/planning/TeacherOnProjectAllocationRepository';
import { TeacherOnSubjectAllocationRepository } from '@/repositories/planning/TeacherOnSubjectAllocationRepository';
import { ClassSubjectDto } from '@/models/planning/ClassSubjectDto';
import { TeacherOnSubjectAllocationDto } from '@/models/planning/TeacherOnSubjectAllocationDto';
import { ProjectsDto } from '@/models/planning/ProjectsDto';
import { TeacherDto } from '@/models/planning/TeacherDto';
import { TeacherOnProjectAllocationDto } from '@/models/planning/TeacherOnProjectAllocationDto';
import { HubConnection } from '@microsoft/signalr';
import SignalRHubs from '@/repositories/SignalRHubs';
import {
  AllocationsType, StateType,
  findTeacherOnSubjectAllocation, findTeacherOnProjectAllocation,
  dataupdateTeacherOnSubjectAllocation, dataupdateTeacherOnProjectAllocation,
  projectAllocationsAdd, teacherProjectAllocationsAdd,
  classSubjectAllocationsAdd, teacherClassSubjectAllocationsAdd,
  calcProjectAllocationStats, calcClassSubjectAllocationStats, calcTeacherAllocationStats
} from '@/store/modules/planning/helpers/DataupdateHelper';
import { Guid } from 'guid-typescript';

interface INullAllocationDialog {
  title: string,
  message: string,
  updateAction: Function;
  deleteAction: Function;
}

class Allocations implements AllocationsType {
  entries: [];
  count: number;
  totalHours: number;
}

class State implements StateType {
  apiState: number = APISTATE.INIT;
  activeSchoolTermId: string | undefined = undefined;
  teacherOnProjectAllocations: TeacherOnProjectAllocationDtoResult = {data: [], totalRecords: 0, skipped: 0, taken: 0};
  teacherOnSubjectAllocations: TeacherOnSubjectAllocationDtoResult = {data: [], totalRecords: 0, skipped: 0, taken: 0};
  classSubjectAllocations: Allocations = new Allocations();
  projectAllocations: Allocations = new Allocations();
  teacherAllocations: Allocations = new Allocations();
  connection: HubConnection = SignalRHubs.dataUpdateHub();
  nullAllocationDialog: Object | undefined = undefined;
}

const mutations = <MutationTree<State>> {
  setApiState(state, apiState: number) {
    state.apiState = apiState;
  },
  setActiveSchoolTermId(state, schoolTermId:string) {
    state.activeSchoolTermId = schoolTermId;
  },
  setTeacherOnProjectAllocationResult(state, result:TeacherOnProjectAllocationDtoResult) {
    state.teacherOnProjectAllocations = result;
    state.teacherOnProjectAllocations.data.forEach(q => q.initialized = false);
  },
  setTeacherOnSubjectAllocationResult(state, result:TeacherOnSubjectAllocationDtoResult) {
    state.teacherOnSubjectAllocations = result;
    state.teacherOnSubjectAllocations.data.forEach(q => q.initialized = false);
  },
  async checkHubConnection(state, myUserId:Guid) {
    if (state.connection.state === "Disconnected") {
      await state.connection.start();
      state.connection.on("TeacherOnSubjectAllocation", (action, modelString, sourceUserId) => {
        if (sourceUserId !== myUserId) {
          const model:TeacherOnSubjectAllocationDto = JSON.parse(modelString);
          dataupdateTeacherOnSubjectAllocation(state, model, action);
        }
      });
      state.connection.on("TeacherOnProjectAllocation", (action, modelString, sourceUserId) => {
        if (sourceUserId !== myUserId) {
          const model = JSON.parse(modelString);
          dataupdateTeacherOnProjectAllocation(state, model, action);
        }
      });
    }
  },

  /**
   * Distribute data in teacherOnProjectAllocation and teacherOnSubjectAllocations to classSubjectAllocations,
   * projectAllocations and teacherAllocations. Determine totalHours and count for each group of allocations.
   * @param state 
   */
  buildAllocations(state) {
    Vue.set(state, "classSubjectAllocations", new Allocations());
    Vue.set(state, "projectAllocations", new Allocations());
    Vue.set(state, "teacherAllocations", new Allocations());

    for (const topa of state.teacherOnProjectAllocations.data) {
      projectAllocationsAdd(state, topa);
      teacherProjectAllocationsAdd(state, topa);
    }

    for (const tosa of state.teacherOnSubjectAllocations.data) {
      classSubjectAllocationsAdd(state, tosa);
      teacherClassSubjectAllocationsAdd(state, tosa);
    }

    calcProjectAllocationStats(state);
    calcClassSubjectAllocationStats(state);
    calcTeacherAllocationStats(state);
  },

  resetData(state) {
    state.teacherOnProjectAllocations = {data: [], totalRecords: 0, skipped: 0, taken: 0};
    state.teacherOnSubjectAllocations = {data: [], totalRecords: 0, skipped: 0, taken: 0};
    state.classSubjectAllocations = new Allocations();
    state.projectAllocations = new Allocations();
    state.teacherAllocations = new Allocations();
  },

  addTeacherOnSubjectAllocation(state, allocation:TeacherOnSubjectAllocationDto) {
    state.teacherOnSubjectAllocations.data.push(allocation);
    state.teacherOnSubjectAllocations.totalRecords++;
  },
  addTeacherOnProjectAllocation(state, allocation:TeacherOnProjectAllocationDto) {
    state.teacherOnProjectAllocations.data.push(allocation);
    state.teacherOnProjectAllocations.totalRecords++;
  },

  removeTeacherOnProjectAllocation(state, allocation:TeacherOnProjectAllocationDto) {
    const index = state.teacherOnProjectAllocations.data.indexOf(findTeacherOnProjectAllocation(state, allocation.relTeacher, allocation.relProject));
    state.teacherOnProjectAllocations.data.splice(index, 1);
    state.teacherOnProjectAllocations.totalRecords--;
  },
  removeTeacherOnSubjectAllocation(state, allocation:TeacherOnSubjectAllocationDto) {
    const index = state.teacherOnSubjectAllocations.data.indexOf(findTeacherOnSubjectAllocation(state, allocation.relTeacher, allocation.relClassSubject));
    state.teacherOnSubjectAllocations.data.splice(index, 1);
    state.teacherOnSubjectAllocations.totalRecords--;
  },

  showNullAllocationDialog(state, dialogObject:INullAllocationDialog) {
    state.nullAllocationDialog = dialogObject;
  },
  closeNullAllocationDialog(state) {
    state.nullAllocationDialog = undefined;
  }
}

const actions = <ActionTree<State, any>> {
  async loadTeacherOnProjectAllocationsForSchoolTerm({commit}, schoolTermId:string) {
    commit('setApiState', APISTATE.LOADING);
    try {
      if (schoolTermId) {
        const {data} = await TeacherOnProjectAllocationRepository.getFiltered(schoolTermId, undefined, undefined, undefined, undefined, undefined);
        commit('setTeacherOnProjectAllocationResult', data);
      }
      commit('setApiState', APISTATE.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
    }
  },

  async loadTeacherOnSubjectAllocationsForSchoolTerm({commit}, schoolTermId:string) {
    commit('setApiState', APISTATE.LOADING);
    try {
      if (schoolTermId) {
        const {data} = await TeacherOnSubjectAllocationRepository.getFiltered(schoolTermId, undefined, undefined, undefined, undefined, undefined, undefined);
        commit('setTeacherOnSubjectAllocationResult', data);
      }
      commit('setApiState', APISTATE.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
    }
  },

  async loadDistributions({commit,dispatch,rootGetters}, schoolTermId:string) {
    await Promise.all([
      dispatch('loadTeacherOnProjectAllocationsForSchoolTerm', schoolTermId),
      dispatch('loadTeacherOnSubjectAllocationsForSchoolTerm', schoolTermId)
    ]);
    dispatch('buildAllocations');
    commit('checkHubConnection', rootGetters['currentUserId']);
    commit('setActiveSchoolTermId', schoolTermId);
  },

  buildAllocations({commit}) {
    commit('buildAllocations');
  },

  resetDistributionStore({commit}) {
    commit('resetData');
  },

  initTeacherOnSubjectAllocation({commit,dispatch,state,rootGetters}, {teacher, classSubject}) {
    const existing = findTeacherOnSubjectAllocation(state, teacher.id, classSubject.id);
    if (!existing) {
      const classSubjectHours = rootGetters['classSubjectStore/classSubjectHoursForPlanning'](classSubject.id);
      const newAllocation: TeacherOnSubjectAllocationDto = {
        hours: classSubjectHours ?? 0.0,
        state: '0',
        relClassSubject: classSubject.id,
        relClassSubjectNavigation: classSubject,
        relTeacher: teacher.id,
        relTeacherNavigation: teacher,
        initialized: true
      };
      commit('addTeacherOnSubjectAllocation', newAllocation);
      if (newAllocation.hours > 0) {
        dispatch('updateTeacherOnSubjectAllocation', newAllocation);
      }
      dispatch('buildAllocations');
    }
  },
  initTeacherOnProjectAllocation({commit,dispatch,state,rootGetters}, {teacher, project}) {
    const existing = findTeacherOnProjectAllocation(state, teacher.id, project.id);
    if (!existing) {
      const projectHours = rootGetters['projectsStore/projectHoursForPlanning'](project.id);
      const newAllocation: TeacherOnProjectAllocationDto = {
        hours: projectHours ?? 0.0,
        state: '0',
        relProject: project.id,
        relProjectNavigation: project,
        relTeacher: teacher.id,
        relTeacherNavigation: teacher,
        initialized: true
      };
      commit('addTeacherOnProjectAllocation', newAllocation);
      if (newAllocation.hours > 0) {
        dispatch('updateTeacherOnProjectAllocation', newAllocation);
      }
      dispatch('buildAllocations');
    }
  },

  async updateTeacherOnSubjectAllocation({commit,dispatch,state}, allocation:TeacherOnSubjectAllocationDto) {
    if (state.nullAllocationDialog === undefined && allocation.hours <= 0) {
      const existing = findTeacherOnSubjectAllocation(state, allocation.relTeacher, allocation.relClassSubject);
      commit('showNullAllocationDialog', {
        title: 'Slet allokering?',
        message: `Slet allokering for ${existing.relTeacherNavigation.firstName} ${existing.relTeacherNavigation.lastName} på ${existing.relClassSubjectNavigation.classId} eller behold som 0 timer?`,
        deleteAction: async (controls:any) => { await dispatch('removeTeacherOnSubjectAllocation', allocation); controls.close(); },
        updateAction: async (controls:any) => { await dispatch('updateTeacherOnSubjectAllocation', allocation); controls.close(); }
      } as INullAllocationDialog);
    }
    else {
      commit('setApiState', APISTATE.LOADING);
      try {
        const existing = findTeacherOnSubjectAllocation(state, allocation.relTeacher, allocation.relClassSubject);
        if (existing.initialized) {
          const {data} = await TeacherOnSubjectAllocationRepository.create(allocation);
          existing.hours = (data.hours < 0) ? 0 : data.hours;
          existing.state = data.state;
          existing.initialized = false;
        }
        else {
          const {data} = await TeacherOnSubjectAllocationRepository.update(allocation);
          existing.hours = (data.hours < 0) ? 0 : data.hours;
          existing.state = data.state;
        }
        dispatch('buildAllocations');
        commit('setApiState', APISTATE.LOADED);
      }
      catch (error) {
        console.error(error);
        commit('setApiState', APISTATE.ERROR);
        throw error;
      }
    }
  },
  async updateTeacherOnProjectAllocation({commit,dispatch,state}, allocation:TeacherOnProjectAllocationDto) {
    if (state.nullAllocationDialog === undefined && allocation.hours <= 0) {
      const existing = findTeacherOnProjectAllocation(state, allocation.relTeacher, allocation.relProject);
      commit('showNullAllocationDialog', {
        title: 'Slet allokering?',
        message: `Slet allokering for ${existing.relTeacherNavigation.firstName} ${existing.relTeacherNavigation.lastName} på ${existing.relProjectNavigation.projectId} eller behold som 0 timer?`,
        deleteAction: async (controls:any) => { await dispatch('removeTeacherOnProjectAllocation', allocation); controls.close(); },
        updateAction: async (controls:any) => { await dispatch('updateTeacherOnProjectAllocation', allocation); controls.close(); }
      } as INullAllocationDialog);
    }
    else {
      commit('setApiState', APISTATE.LOADING);
      try {
        const existing = findTeacherOnProjectAllocation(state, allocation.relTeacher, allocation.relProject);
        if (existing.initialized) {
          const {data} = await TeacherOnProjectAllocationRepository.create(allocation);
          existing.hours = (data.hours < 0) ? 0 : data.hours;
          existing.state = data.state;
          existing.initialized = false;
        }
        else {
          const {data} = await TeacherOnProjectAllocationRepository.update(allocation);
          existing.hours = (data.hours < 0) ? 0 : data.hours;
          existing.state = data.state;
        }
        dispatch('buildAllocations');
        commit('setApiState', APISTATE.LOADED);
      }
      catch (error) {
        console.error(error);
        commit('setApiState', APISTATE.ERROR);
        throw error;
      }
    }
  },

  async removeTeacherOnProjectAllocation({commit,state}, allocation:TeacherOnProjectAllocationDto) {
    commit('setApiState', APISTATE.LOADING);
    try {
      const existing = findTeacherOnProjectAllocation(state, allocation.relTeacher, allocation.relProject);
      if (!existing.initialized) {
        await TeacherOnProjectAllocationRepository.remove(allocation.relTeacher, allocation.relProject);
        commit('removeTeacherOnProjectAllocation', allocation);
        commit('buildAllocations');
        commit('setApiState', APISTATE.LOADED);
      }
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
      throw error;
    }
  },
  async removeTeacherOnSubjectAllocation({commit,state}, allocation:TeacherOnSubjectAllocationDto) {
    commit('setApiState', APISTATE.LOADING);
    try {
      const existing = findTeacherOnSubjectAllocation(state, allocation.relTeacher, allocation.relClassSubject);
      if (!existing.initialized) {
        await TeacherOnSubjectAllocationRepository.remove(allocation.relTeacher, allocation.relClassSubject);
        commit('removeTeacherOnSubjectAllocation', allocation);
        commit('buildAllocations');
        commit('setApiState', APISTATE.LOADED);
      }
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
      throw error;
    }
  },

  closeNullAllocationDialog({commit}) {
    commit("closeNullAllocationDialog");
  },
  cancelNullAllocationDialog({dispatch,state}) {
    dispatch('loadDistributions', state.activeSchoolTermId);
  }
}

const checkStateObject = (stateObject:Object, key:any) => 
  stateObject && Object.prototype.hasOwnProperty.call(stateObject, key);

const getters = <GetterTree<State, any>> {
  distributionApiState: state => state.apiState,

  allocationsForClassSubject: state => (model:ClassSubjectDto) => {
    const result = checkStateObject(
      state.classSubjectAllocations, 
      `${model.id}`) ? 
        state.classSubjectAllocations[`${model.id}`].entries : 
        [];
    
    return result;
  },

  allocationsForProject: state => (model:ProjectsDto) =>
    checkStateObject(state.projectAllocations, `${model.id}`) ? state.projectAllocations[`${model.id}`].entries : [],

  allocationsForTeacher: state => (model:TeacherDto) =>
    checkStateObject(state.teacherAllocations, `${model.id}`) ? state.teacherAllocations[`${model.id}`].entries : [],

  hoursForClassSubject: state => (model:ClassSubjectDto) =>
    checkStateObject(state.classSubjectAllocations, `${model.id}`) ? state.classSubjectAllocations[`${model.id}`].totalHours : 0,

  hoursForProject: state => (model:ProjectsDto) =>
    checkStateObject(state.projectAllocations, `${model.id}`) ? state.projectAllocations[`${model.id}`].totalHours : 0,

  hoursForTeacher: state => (model:TeacherDto) =>
    checkStateObject(state.teacherAllocations, `${model.id}`) ? state.teacherAllocations[`${model.id}`].totalHours : 0,

  isLoading: state => state.apiState === APISTATE.LOADING,

  nullAllocationDialog: (state) => state.nullAllocationDialog,
  showNullAllocationDialog: (state) => state.nullAllocationDialog !== undefined,

}

const DistributionStore = {
  namespaced: true,
  state: new State(),
  mutations,
  actions,
  getters
};

export default DistributionStore;
