import PlanningClient from '@/clients/PlanningClient';
import { TeacherOnSubjectAllocationDto } from '@/models/planning/TeacherOnSubjectAllocationDto';
import { TeacherOnSubjectAllocationDtoResult } from '@/models/planning/TeacherOnSubjectAllocationDtoResult';
import { Guid } from 'guid-typescript';
import qs from 'qs';
import { ITeacherOnSubjectAllocationRepository } from "../interfaces/planning/ITeacherOnSubjectAllocationRepository";

const resource = '/teacherOnSubjectAllocation';

export const TeacherOnSubjectAllocationRepository : ITeacherOnSubjectAllocationRepository = {
  getFiltered: (schoolTerm:string, classIds:string[]|undefined, departments:number[]|undefined, educationalDirections:number[]|undefined, schoolSubjects:number[]|undefined, skip:number|undefined, take:number|undefined) =>
    PlanningClient.get(`${resource}/filtered/${schoolTerm}`, {
      params: {
        classIds: classIds,
        departments: departments,
        educationalDirections: educationalDirections,
        schoolSubjects: schoolSubjects,
        skip: skip ?? '',
        take: take ?? ''
      },
      paramsSerializer: params => qs.stringify(params), // to format array parameters in a way that .net core will pick up by default
    }) as Promise<{data:TeacherOnSubjectAllocationDtoResult}>,

  create: (form:TeacherOnSubjectAllocationDto) =>
    PlanningClient.post(`${resource}`, form) as Promise<{data:TeacherOnSubjectAllocationDto}>,
  
  update: (form:TeacherOnSubjectAllocationDto) =>
    PlanningClient.put(`${resource}`, form) as Promise<{data:TeacherOnSubjectAllocationDto}>,
  
  remove: (relTeacher:Guid, relClassSubject:Guid) =>
    PlanningClient.delete(`${resource}/${relTeacher}/${relClassSubject}`),

}
