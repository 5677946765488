










import Vue from 'vue'

export default Vue.extend({
  name: "input-number",
  props: ["value"],
  data: () => ({
    displayValue: undefined as string | undefined,
    valueBeforeInput: undefined as string | undefined,
  }),
  mounted() {
    this.displayValue = this.value;
  },
  methods: {
    valueChange(event: InputEvent) {
      const target = event.target as HTMLInputElement;
      const value = Number(target.value.replace(",", "."));
    
      if (!isNaN(value)) {
        this.displayValue = target.value.replace(".", ",");
        this.$emit("input", value);
      } else {
        target.value = this.displayValue;
      }
    },
    onBlur(event: Event) {
      if (!this.valueHasChanged) return;

      this.$emit('blur', event);
    },
    onFocus(event: Event) {
      this.valueBeforeInput = this.displayValue;
      this.$emit('focus', event);
    },
  },
  computed: {
    valueHasChanged() {
      // console.log(this.displayValue, this.valueBeforeInput);
      
      return this.displayValue !== this.valueBeforeInput;
    }
  },
  watch: {
    value(newValue) {
      this.displayValue = newValue;
    }
  }
})
