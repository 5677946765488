import PlanningClient from '@/clients/PlanningClient';
import { SchoolTermDeleteCheckDto } from '@/models/planning/SchoolTermDeleteCheckDto';
import { SchoolTermDto } from '@/models/planning/SchoolTermDto';
import { ISchoolTermRepository } from '../interfaces/planning/ISchoolTermRepository';

const resource = '/schoolterm';

export const SchoolTermRepository: ISchoolTermRepository = {
  getAll: () => PlanningClient.get(`${resource}`) as Promise<{ data: SchoolTermDto[]; }>,
  getOne: (schoolTermId: string) => PlanningClient.get(`${resource}/${schoolTermId}`) as Promise<{ data: SchoolTermDto; }>,
  create: (formSchoolTerm: SchoolTermDto) => PlanningClient.post(`${resource}`, formSchoolTerm) as Promise<{ data: SchoolTermDto; }>,
  update: (formSchoolTerm: SchoolTermDto) => PlanningClient.put(`${resource}`, formSchoolTerm) as Promise<{ data: SchoolTermDto; }>,
  remove: (schoolTermId: string) => PlanningClient.delete(`${resource}/${schoolTermId}`),
  checkDelete: (schoolTermId: string) => PlanningClient.get(`${resource}/checkDelete/${schoolTermId}`) as Promise<{ data: SchoolTermDeleteCheckDto; }>,
  downloadExcel: (schoolTermId: string) => PlanningClient.get(`${resource}/downloadExcel/${schoolTermId}`, { responseType: "blob" }) as Promise<{ data: Blob; }>,
};
