import type { GetterTree, MutationTree, ActionTree } from 'vuex';
import loadingState from '@/enums/APISTATE';
import { SchoolSubjectDto } from '@/models/planning/SchoolSubjectDto';
import { SchoolSubjectRepository } from '@/repositories/planning/SchoolSubjectRepository';

const byTitle = (a:SchoolSubjectDto, b:SchoolSubjectDto) => {
  const aValue = `${a.title}`.toUpperCase();
  const bValue = `${b.title}`.toUpperCase();
  return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
};

class State {
  schoolSubjects: SchoolSubjectDto[] = [];
  loadingState: number = loadingState.INIT;
}

const mutations = <MutationTree<State>> {
  setSchoolSubjects(state, schoolSubjects) {
    state.schoolSubjects = schoolSubjects;
  },
  setState(state, loadingState) {
    state.loadingState = loadingState;
  },
};

const actions = <ActionTree<State, any>> {
  async loadSchoolSubjects({ commit }) {
    try {
      commit("setState", loadingState.LOADING);
      const { data } = await SchoolSubjectRepository.getAll();
      commit("setSchoolSubjects", data);
      commit("setState", loadingState.LOADED);
    } catch {
      commit("setState", loadingState.ERROR);
    }
  },
};

const getters = <GetterTree<State, any>> {
  schoolSubjects: state => state.schoolSubjects.sort(byTitle),
  isLoading: state => state.loadingState == loadingState.LOADING
};

const schoolSubjectsStore = {
  namespaced: true,
  state: new State(),
  mutations,
  actions,
  getters
};

export default schoolSubjectsStore;
