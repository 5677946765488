import type { GetterTree, MutationTree, ActionTree } from 'vuex';
import { ProjectsDto } from '@/models/planning/ProjectsDto';
import { ProjectsDtoResult } from '@/models/planning/ProjectsDtoResult';
import APISTATE from '@/enums/APISTATE';
import { ProjectsRepository } from "@/repositories/planning/ProjectsRepository";
import { ProjectCriteriaModel, projectFilter } from '@/models/filterpanel/ProjectCriteriaModel';
import { Guid } from 'guid-typescript';

type filter = {
  department: number | undefined;
  schoolTerm: string | undefined;
  skip: number | undefined;
  take: number | undefined;
}

const byId = (a:ProjectsDto, b:ProjectsDto) => {
  const aValue = `${a.projectId}`.toUpperCase();
  const bValue = `${b.projectId}`.toUpperCase();
  return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
}

class State {
  apiState: number = APISTATE.INIT;
  project: ProjectsDto | {} = {};
  projects: ProjectsDto[] = [];
  projectsTotal: number = 0;
  projectsTaken: number = 0;
  projectsSkipped: number = 0;
  filter: filter = {
    department: undefined,
    schoolTerm: undefined,
    skip: undefined,
    take: undefined,
  };

  criteriaFilter = {} as ProjectCriteriaModel; 
  filteredProjects = [] as ProjectsDto[];
  mappedProjects = [] as unknown[];
}

const mutations = <MutationTree<State>> {
  setApiState(state, apiState: number) {
    state.apiState = apiState;
  },
  setProject(state, project: ProjectsDto) {
    state.project = project;
  },
  setProjects(state, projects: ProjectsDto[]) {
    state.projects = projects;
  },
  setProjectsResult(state, projects: ProjectsDtoResult) {
    state.projects = projects.data;
    state.projectsTotal = projects.totalRecords;
    state.projectsTaken = projects.taken;
    state.projectsSkipped = projects.skipped;
  },
  setFilterProperty(state, {departments, schoolTerms, skip, take}) {
    if (departments) state.filter.department = departments;
    if (schoolTerms) state.filter.schoolTerm = schoolTerms;
    if (skip) state.filter.skip = skip;
    if (take) state.filter.take = take;
  },
  clearFilterProperty(state, propertyName: keyof filter) {
    if (propertyName in state.filter) {
      state.filter[propertyName as string] = undefined;
    }
  },
  resetData(state) {
    state.project = {};
    state.projects = [];
    state.projectsTotal = 0;
    state.projectsTaken = 0;
    state.projectsSkipped = 0;
   },
   setCriteriaFilter(state, filter: ProjectCriteriaModel) {
    state.criteriaFilter = filter;
    },
    setFilteredProjects(state, filteredProjects: ProjectsDto[]) {
      state.filteredProjects = projectFilter(state.projects, state.criteriaFilter).sort(byId);    
    },
    mapProjects(state, filteredClassSubjects: ProjectsDto[]) {
      const output = {};

      for (const project of state.filteredProjects) {
        const projectTypeTitle = project.relProjectTypeNavigation.title;
        if (!Object.prototype.hasOwnProperty.call(output, projectTypeTitle)) {
          output[projectTypeTitle] = { label: projectTypeTitle, entries: [] };
        }
        output[projectTypeTitle].entries.push(project);

        output[projectTypeTitle].entries.sort(byId);
        // output[projectTypeTitle].entries.sort((a,b) => {
        //   const aValue = `${a.id}`.toUpperCase();
        //   const bValue = `${b.id}`.toUpperCase();
        //   return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
        // });
      }

      const mappedProjects = Object.values(output).sort((a:any,b:any) => a.label.toUpperCase() > b.label.toUpperCase() ? 1 : a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 0 );
      state.mappedProjects = mappedProjects;
   }
};

const actions = <ActionTree<State, any>> {
  async loadProjects({commit, state}) {
    commit('setApiState', APISTATE.LOADING);
    try {
      const { data } = await ProjectsRepository.getFiltered(state.filter.department, state.filter.schoolTerm, state.filter.skip, state.filter.take);
      commit('setProjectsResult', data);
      commit('setApiState', APISTATE.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
    }
  },

  async loadProjectsBySchoolTerm({commit}, schoolTermId:string) {
    commit('setApiState', APISTATE.LOADING);
    try {
      const { data } = await ProjectsRepository.getFiltered(undefined, schoolTermId, undefined, undefined);
      commit('setProjectsResult', data);
      commit('setApiState', APISTATE.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
    }
  },

  async setFilterProperty({commit, dispatch}, {departments, schoolTerms, skip, take}) {
    commit('setFilterProperty', {departments, schoolTerms, skip, take});
    await dispatch('loadProjects');
  },
  async clearFilterProperty({commit, dispatch}, propertyName) {
    commit('clearFilterProperty', propertyName);
    await dispatch('loadProjects');
  },
  async loadProject({commit}, projectId:Guid) {
    commit('setApiState', APISTATE.LOADING);
    try {
      const { data } = await ProjectsRepository.getById(projectId);
      commit('setProject', data);
      commit('setApiState', APISTATE.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
    }
  },
  async createProject({commit, dispatch}, formProject) {
    commit('setApiState', APISTATE.LOADING);
    try {
      const { data } = await ProjectsRepository.create(formProject);
      await dispatch('loadProject', data.id);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
      throw error;
    }
  },
  async updateProject({commit, dispatch}, formProject) {
    commit('setApiState', APISTATE.LOADING);
    try {
      const { data } = await ProjectsRepository.update(formProject);
      await dispatch('loadProject', data.id);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
      throw error;
    }

  },
  async removeProject({commit}, projectId) {
    commit('setApiState', APISTATE.LOADING);
    try {
      await ProjectsRepository.remove(projectId);
      commit('setApiState', APISTATE.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
      throw error;
    }
  },

  resetProjectsStore({commit}) {
    commit('resetData');
  },

  filterProjects({commit, state}, filter: ProjectCriteriaModel) {    
    commit('setCriteriaFilter', filter ?? state.criteriaFilter)
    commit('setFilteredProjects');
    commit('mapProjects');
  }
};

const getters = <GetterTree<State, any>> {
  projectsApiState: state => state.apiState,
  projects: state => state.projects.sort(byId),
  project: state => state.project,
  mappedProjects:state => state.mappedProjects, 
  projectsFiltered: state => state.filteredProjects,
  projectHoursForPlanning: state => (projectId:Guid) => {
    const project = state.projects.find((q:ProjectsDto) => q.id === projectId);
    let result = undefined;
    if (project) {
      result = project.numberOfPlanningHours;
    }
    return isNaN(result) ? undefined : result;
  },

};

const ProjectsStore = {
  namespaced: true,
  state: new State(),
  mutations,
  actions,
  getters
};

export default ProjectsStore;
