import PlanningClient from '@/clients/PlanningClient';
import { ProjectTypeDto } from '@/models/planning/ProjectTypeDto';
import { IProjectTypeRepository } from '../interfaces/planning/IProjectTypeRepository';

const resource = '/projecttype';

export const ProjectTypeRepository: IProjectTypeRepository = {
  getAll: () => PlanningClient.get(`${resource}`) as Promise<{ data:ProjectTypeDto[] }>,

};
