import Repository from '@/repositories/RepositoryFactory';
import loadingState from "@/enums/APISTATE";

const repo = Repository.get("educationalDirections");

const byTitle = (a,b) => {
  const aValue = `${a.title}`.toUpperCase();
  const bValue = `${b.title}`.toUpperCase();
  return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
};

export default {
  namespaced: true,
  state: {
    state: loadingState.INIT,
    educationDirections: []
  },
  mutations: {
    setState(state, value) {
      state.state = value;
    },
    setDirections(state, value) {
      state.educationDirections = value;
    }
  },
  actions: {
    async loadEducationalDirections({ commit }) {
      try {
        commit("setState", loadingState.LOADING);
        const educationDirections = await repo.getAll();
        commit("setDirections", educationDirections.data);
        commit("setState", loadingState.LOADED);
      } catch {
        commit("setState", loadingState.ERROR);
      }
    }
  },
  getters: {
    state: state => state.state,
    isLoading: state => state.state === loadingState.LOADING,
    educationDirections: (state) => (key) => key ? state.educationDirections.filter(q => q.groups.includes(key)).sort(byTitle) : state.educationDirections.sort(byTitle),
  }
}
