import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Buefy from 'buefy';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { icons } from "./IconDeclerations";

import Multiselect from 'vue-multiselect';
import VueNumeralFilterInstaller from 'vue-numeral-filter';

import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";

import TabComponent from '@/components/TabComponent.vue';
import SectionHeader from '@/components/SectionHeader.vue';
import DialogModal from '@/components/DialogModal.vue';
import Sidebar from "@/components/Sidebar.vue";
import InputNumber from "@/components/InputNumber.vue";
import Foldable from "@/components/Foldable.vue";
import Icon from "@/components/Icon.vue";

library.add(...icons);

require('./styles/app.scss');

Vue.config.productionTip = false

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('v-multiselect', Multiselect);
Vue.component('tab-component', TabComponent);
Vue.component('section-header', SectionHeader);
Vue.component('dialog-modal', DialogModal);
Vue.component('sidebar', Sidebar);
Vue.component('input-number', InputNumber);
Vue.component('foldable', Foldable);
Vue.component('icon', Icon);

Vue.use(Buefy, {
  defaultIconComponent: 'font-awesome-icon',
  defaultIconPack: 'fas',
  defaultContainerElement: '#content',
  defaultModalScroll: 'keep'
});

Vue.use(VueNumeralFilterInstaller, {
  locale: 'da-dk'
});

new Vue({
  router,
  store,
  beforeCreate() {
    this.$store.commit('initializeStore');
  },
  render: h => h(App)
}).$mount('#app')
