import type { GetterTree, MutationTree, ActionTree } from 'vuex';
import { financedOptions } from '@/repositories/ConstantsRepository';
import { FinancedDto } from '@/models/ConstantsDto';

class State {
  financedOptions: FinancedDto[] = financedOptions;

}

const mutations = <MutationTree<State>> {
};
const actions = <ActionTree<State, any>> {
};
const getters = <GetterTree<State, any>> {
  financedOptions: state => state.financedOptions,
  
}

const ConstantsStore = {
  namespaced: true,
  state: new State(),
  mutations,
  actions,
  getters
};

export default ConstantsStore;
