export const distribution = [
  {
    path: '/distribution',
    component: () => import('@/views/distribution/Distribution.vue'),
    children: [
      {
        path: '',
        name: 'Distribution',
        component: () => import('@/views/distribution/tabs/Planning.vue'),
        props: true
      },
      {
        path: 'remoteEducation',
        name: 'remoteEducation',
        component: () => import('@/views/distribution/tabs/RemoteEducation.vue'),
        props: true
      }
    ]
  }
];
