import type { GetterTree, MutationTree, ActionTree } from 'vuex';
import Vue from 'vue';
import { ClassSubjectDragInfo, DragItemInfo, ProjectDragInfo, TeacherDragInfo } from '@/models/core/DragItemInfo';
import { ClassSubjectDto } from '@/models/planning/ClassSubjectDto';
import { ProjectsDto } from '@/models/planning/ProjectsDto';
import { TeacherDto } from '@/models/planning/TeacherDto';

type dragArguments = {
  dragEvent: DragEvent,
  dragItemInfo: DragItemInfo
}

type dropListener = {
  source: string;
  target: string;
  action: Function;
}

class State {
  dragItemInfo: DragItemInfo | undefined = undefined;
  dragHover: DragItemInfo | undefined = undefined;
  dropListeners: dropListener[] = [];
}

const mutations = <MutationTree<State>> {
  dragStart(state, dragItemInfo:DragItemInfo) {
    state.dragItemInfo = dragItemInfo;
  },
  dragEnd(state) {
    state.dragItemInfo = undefined;
    state.dragHover = undefined;
  },
  dragHover(state, dragItemInfo:DragItemInfo) {
    state.dragHover = dragItemInfo;
  },
  addDropListener(state, listener: dropListener) {
    const existing = state.dropListeners.filter(q => q.action === listener.action);
    if (existing.length === 0) {
      state.dropListeners.push(listener);
    }
  },
  callDropListeners(state, target:DragItemInfo) {
    const listeners = state.dropListeners.filter(q => q.source === state.dragItemInfo.type && q.target === target.type);
    if (listeners) {
      for (const listener of listeners) {
        listener.action(state.dragItemInfo, target);
      }
    }
  }
}

const actions = <ActionTree<State, any>> {
  dragStart({commit}, {dragEvent, dragItemInfo}: dragArguments) {
    dragEvent.dataTransfer.setData('type', dragItemInfo.type);
    commit('dragStart', dragItemInfo);
  },
  drop({commit}, {dragEvent, dragItemInfo}: dragArguments) {
    const dataType = dragEvent.dataTransfer.getData('type');
    commit('callDropListeners', dragItemInfo);
  },
  dragEnd({commit}) {
    commit('dragEnd');
  },
  dragEnter({commit}, {dragEvent, dragItemInfo}: dragArguments) {
    commit('dragHover', dragItemInfo);
    dragEvent.preventDefault();
    return false;
  },
  dragLeave({commit,state}, {dragEvent, dragItemInfo}: dragArguments) {
    if (state.dragHover === dragItemInfo) {
      commit('dragHover', undefined);
    }
  },
  registerDropListener({commit}, {source, target, action}:dropListener) {
    commit('addDropListener', {source, target, action});
  }

}

const getters = <GetterTree<State, any>> {
  canDrop: () => (source: DragItemInfo, target: DragItemInfo) => target.canDrop(source),
  getClassSubjectDragInfo: () => (source: ClassSubjectDto) => new ClassSubjectDragInfo(source),
  getProjectDragInfo: () => (source: ProjectsDto) => new ProjectDragInfo(source),
  getTeacherDragInfo: () => (source: TeacherDto) => new TeacherDragInfo(source),
  dragOver: state => ({dragEvent, dragItemInfo}: dragArguments) => {
    if (state.dragItemInfo.canDrop(dragItemInfo)) {
      dragEvent.preventDefault();
      return false;
    }
    else {
      return true;
    }
  },
  dragTarget: state => (target:DragItemInfo) => state.dragItemInfo && state.dragItemInfo.canDrop(target),
  dragTargetHover: state => (target:DragItemInfo) => state.dragHover === target,
}

const dragAndDropStore = {
  namespaced: true,
  state: new State(),
  mutations,
  actions,
  getters
};

export default dragAndDropStore;
