<template>
  <div class="section__header">
    <div class="section__header__title">
      <slot name="title">
        <h2>
          {{ title }}
        </h2>
      </slot>
    </div>
    <div class="section__header__default">
      <slot>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'section-header',
  props: {
    title: {
      type: String
    }
  }
}
</script>

<style lang="scss">
.section__header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 30px 0 10px 0;
  min-height: 35px;

  // Raise controls over Bulma's insistance on using negative margins, so they're not partially covered.
  position: relative;
  z-index: 1;

  .section__header__title {
    align-self: center;
  }
  .section__header__default {
    display: flex;
    flex-flow: row nowrap;
    gap: 16px;
  }

  .button {
    min-width: 170px;
  }

  &.light {
    background-color: $light;
  }
  &.darker {
    background-color: #e0e0e0;
  }
  &.split {
    margin-right: 0;
  }
}
</style>