






















import Vue from "vue";
import { mapActions } from 'vuex';

export default Vue.extend({
  name: "sidebar",
  props: ["value", "title"],
  methods: {
    ...mapActions('validationStore', ['clearErrors']),
    close() {
      this.$emit("input", false);
      this.clearErrors();
    },
  },
});
