export const schoolTerms = [
  {
    path: '/schoolterms',
    name: 'SchoolTerms',
    component: () => import('@/views/schoolterms/SchoolTerms.vue')
  },
  {
    path: '/schoolterm/new',
    name: 'NewSchoolTerm',
    component: () => import('@/views/schoolterms/SchoolTerm.vue')
  },
  {
    path: '/schoolterm/:schoolTermId',
    name: 'EditSchoolTerm',
    props: true,
    component: () => import("@/views/schoolterms/SchoolTerm.vue")
  }
];