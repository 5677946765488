import AppUsersClient from "../clients/AppUsersClient";
const resource = '/appusers';

export default {
  getAll() {
    return AppUsersClient.get(`${resource}`);
  },
  getOne(id) {
    return AppUsersClient.get(`${resource}/${id}`);
  },
  registerAppUser(userDto) {
    return AppUsersClient.post(`${resource}`, userDto);
  },
  updateAppUser(userDto) {
    return AppUsersClient.put(`${resource}`, userDto);
  },
  setAppUserPassword(id, form) {
    return AppUsersClient.post(`${resource}/${id}/password`, form);
  },
  deleteAppUser: (id) => AppUsersClient.delete(`${resource}/${id}`),
  passwordReset: (email) => AppUsersClient.post(`${resource}/passwordreset`, { email })

}
