import type { GetterTree, MutationTree, ActionTree } from 'vuex';
import APISTATE from '@/enums/APISTATE';
import { GroupResult } from '@/models/dashboard/GroupResult';
import { DashboardViewModel } from '@/models/dashboard/DashboardViewModel';
import { DashboardRepository } from '@/repositories/planning/DashboardRepository';

type TabGroupOption = {
  key: string;
  value: string;
}

class State {
  apiState: number = APISTATE.INIT;
  groupResult: GroupResult|undefined = undefined;
  tabGroupOptions: {string:TabGroupOption[]}|{} = {};
  tabGroupOptionKey: string|undefined = undefined;
}

const mutations = <MutationTree<State>> {
  setApiState(state, apiState: number) {
    state.apiState = apiState;
  },
  setTabGroupOptions(state:State, tabGroupOptions:{string:TabGroupOption[]}|{}) {
    state.tabGroupOptions = tabGroupOptions;
  },
  setTabGroupOptionKey(state:State, key:string) {
    state.tabGroupOptionKey = key;
  },
  setGroupResult(state:State, groupResult:GroupResult|undefined) {
    state.groupResult = groupResult;
  },

}

const checkViewModel = (vm:DashboardViewModel) => (vm && vm.groupBy && vm.schoolTerm);

const actions = <ActionTree<State, any>> {
  async loadTabGroupOptions({commit}) {
    commit('setApiState', APISTATE.LOADING);
    try {
      const { data: tabGroupOptions } = await DashboardRepository.getTabGroupOptions();
      commit('setTabGroupOptions', tabGroupOptions);
      commit('setApiState', APISTATE.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
    }
  },
  setTabGroupOptionKey({commit}, key:string) {
    commit('setTabGroupOptionKey', key);
  },

  async loadClassSubjectAverage({commit}, viewModel:DashboardViewModel) {
    if (!checkViewModel(viewModel)) {
      return undefined;
    }

    commit('setApiState', APISTATE.LOADING);
    try {
      const { data: groupResult } = await DashboardRepository.classSubjectAverage(viewModel);
      commit('setGroupResult', groupResult);
      commit('setApiState', APISTATE.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
    }
  },

  async loadLessonHoursAverage({commit}, viewModel:DashboardViewModel) {
    if (!checkViewModel(viewModel)) {
      return undefined;
    }

    commit('setApiState', APISTATE.LOADING);
    try {
      const { data: groupResult } = await DashboardRepository.lessonHoursAverage(viewModel);
      commit('setGroupResult', groupResult);
      commit('setApiState', APISTATE.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
    }
  },

  async loadAnnualManhoursAverage({commit}, viewModel:DashboardViewModel) {
    if (!checkViewModel(viewModel)) {
      return undefined;
    }

    commit('setApiState', APISTATE.LOADING);
    try {
      const { data: groupResult } = await DashboardRepository.annualManhoursAverage(viewModel);
      commit('setGroupResult', groupResult);
      commit('setApiState', APISTATE.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
    }
  },

}

const getters = <GetterTree<State, any>> {
  groupEntries: (state) => (state.groupResult) ? state.groupResult.groupEntries : [],
  groupKeyLabel: (state) => (state.groupResult) ? state.groupResult.keyLabel : '--',
  groupValueLabel: (state) => (state.groupResult) ? state.groupResult.valueLabel : '--',
  infoboxLabel: (state) => (state.groupResult) ? state.groupResult.infoboxLabel : '--',
  infoboxValue: (state) => (state.groupResult) ? state.groupResult.infoboxValue : '--',
  tabGroupOptions: (state) => Object.prototype.hasOwnProperty.call(state.tabGroupOptions, state.tabGroupOptionKey) ? state.tabGroupOptions[state.tabGroupOptionKey] : [],

}

const DashboardStore = {
  namespaced: true,
  state: new State(),
  mutations,
  actions,
  getters
};

export default DashboardStore;
