import Repository from '@/repositories/RepositoryFactory';
import loadingState from "@/enums/APISTATE";

const repo = Repository.get("employmentTypes");

export default {
  namespaced: true,
  state: {
    state: loadingState.INIT,
    employmentTypes: null
  },
  mutations: {
    setState(state, value) {
      state.state = value;
    },
    setEmploymentTypes(state, value) {
      state.employmentTypes = value;
    }
  },
  actions: {
    async loadEmploymentTypes({ commit }) {
      try {
        commit("setState", loadingState.LOADING);
        const employmentTypes = await repo.getAll();
        commit("setEmploymentTypes", employmentTypes.data);
        commit("setState", loadingState.LOADED);
      } catch {
        commit("setState", loadingState.ERROR);
      }
    }
  },
  getters: {
    state: state => state.state,
    employmentTypes: state => state.employmentTypes,
  }
}