<template>
  <div :class="['modal', open && 'is-active']">
    <div class="modal-background">
    </div>
    <slot name="content" :controls="{close,cancel}">
      <div class="modal-card">
        <header class="modal-card-head">
          <slot name="title" :controls="{close,cancel}">
            <p class="modal-card-title">
              {{ title }}
            </p>
          </slot>
          <button class="delete" aria-label="close" @click="cancel"></button>
        </header>
        <section class="modal-card-body">
          <slot :controls="{close}">
          </slot>
        </section>
        <footer class="modal-card-foot">
          <slot name="additionalControls" :controls="{close,cancel}">
          </slot>
          <slot name="controls" :controls="{close,cancel}">
            <button class="button is-dark" @click="cancel">Afbryd</button>
          </slot>
        </footer>
      </div>
    </slot>
    <button class="modal-close" aria-label="close" @click="cancel"></button>
  </div>
</template>

<script>
export default {
  name: 'dialog-modal',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Uerklæret titel'
    }
  },
  data: () => ({
    open: false
  }),
  methods: {
    cancel() {
      this.$emit('cancelled');
      this.close();
    },
    close() {
      this.open = false;
    }
  },
  watch: {
    'isOpen'(newValue) {
      this.open = newValue;
    },
    'open'(newValue) {
      this.$emit(newValue ? 'opened' : 'closed');
    }
  }
}
</script>
