<template>
  <b-sidebar type="is-sidebarmenu"
             :fullheight="true"
             :overlay="false"
             :right="false"
             :open="sidebarMenuOpen"
             @close="sidebarMenuClosed">
    <div class="sidebar__menu">
      <div class="sidebar__menu__header">
        <div class="sidebar__menu--closebutton" @click="sidebarMenuClosed">
        </div>
      </div>
      <div class="sidebar__menu__content">
        <router-link v-for="(link, index) in currentLinks" :key="index" :to="link.to">
          {{ link.title }}
        </router-link>
      </div>
      <div class="sidebar__menu__footer">
        <div class="sidebar__menu__footer__controls">
          <router-link :to="{name: 'ChangePassword'}">
            <div class="sidebar__menu__item--active">
              Nulstil adgangskode
            </div>
          </router-link>
          <div>
            |
          </div>
          <div class="sidebar__menu__item--active" @click="signOutOidc">
            Log af
          </div>
        </div>
        <div>
          {{ this.siteVersion }}
        </div>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'sidebar-menu',
  data: () => ({
    links: [
      { title: 'Dashboard', to: {name: 'Holdgennemsnit'} },
      { title: 'Timefag fordeling', to: {name: 'Distribution'} },
      { title: 'Undervisere', to: '/teachers' },
      { title: 'Fagtilbud', to: {name: 'ClassSubjects'} },
      { title: 'Opgaver', to: {name: 'Projects'} },
      { title: 'Semestre', to: {name: 'SchoolTerms'} }
    ]
  }),
  computed: {
    ...mapGetters('sidebarMenuStore', ['sidebarMenuOpen']),
    ...mapGetters(['siteVersion', 'isAdmin']),
    ...mapGetters("oidcStore", ["oidcIsAuthenticated"]),
    currentLinks() {
      let output = [...this.links];
      if (this.isAdmin) {
        output.push({ title: 'Bruger administration', to: {name: 'Users'} });
      }
      return output;
    }
  },
  methods: {
    ...mapActions('oidcStore', ['signOutOidc']),
    ...mapActions('sidebarMenuStore', ['sidebarMenuClosed'])
  },
}
</script>

<style lang="scss">
.sidebar__menu {
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  
  .sidebar__menu__header {
    flex: 0 0 60px;
    background-image: url(../assets/vucstorstroemlogo-noslogan.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 22%;
  }
  .sidebar__menu__content {
    flex: 1 0 auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    padding-top: 100px;

    a {
      width: 100%;
      height: 50px;
      border-top: 1px solid $sidebarmenu-color;
      display: flex;
      align-items: center;
      padding: 0 40px;
      background-image: url(../assets/angle-right-regular.svg);
      background-repeat: no-repeat;
      background-position-y: center;
      background-position-x: 95%;
      color: $sidebarmenu-color;
    }
    a:last-child {
      border-bottom: 1px solid $sidebarmenu-color;
    }
  }
  .sidebar__menu__footer {
    flex: 0 0 80px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    font-size: 0.9em;    
  }
  .sidebar__menu__footer__controls {
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    a {
      color: $sidebarmenu-color;
    }
  }

  .sidebar__menu--closebutton {
    width: 60px;
    height: 60px;
    background-image: url(../assets/close.svg);
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }
  .sidebar__menu__item--active {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>