import axios from 'axios';
import store from '../store';
import { apiConfig } from '../config/apiConfig';

const baseDomain = `${apiConfig.url}`;
const baseURL = `${baseDomain}`;

const axiosClient = axios.create({
  baseURL,
  headers: {
    'crossorigin': 'true',
    'Content-Type': 'application/json',
  }
});

axiosClient.interceptors.request.use(req => {
  req.headers.authorization = `Bearer ${store?.getters['oidcStore/oidcAccessToken']}`;
  return req;
});

export default axiosClient;
