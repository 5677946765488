import type { GetterTree, MutationTree, ActionTree } from 'vuex';
import APISTATE from '@/enums/APISTATE';
import { StudentTypeDto } from '@/models/planning/StudentTypeDto';
import { StudentTypeRepository } from '@/repositories/planning/StudentTypeRepository';

const byTitle = (a:StudentTypeDto, b:StudentTypeDto) => {
  const aValue = `${a.title}`.toUpperCase();
  const bValue = `${b.title}`.toUpperCase();
  return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
};

class State {
  apiState: Number = APISTATE.INIT;
  studentTypes: StudentTypeDto[] = [];
}

const mutations = <MutationTree<State>> {
  setApiState(state, apiState: Number) {
    state.apiState = apiState;
  },
  setStudentTypes(state, studentTypes: StudentTypeDto[]) {
    state.studentTypes = studentTypes;
  },

}

const actions = <ActionTree<State, any>> {
  async loadStudentTypes({commit}) {
    commit('setApiState', APISTATE.LOADING);
    try {
      const { data } = await StudentTypeRepository.getAll();
      commit('setStudentTypes', data);
      commit('setApiState', APISTATE.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
    }
  },
  
}

const getters = <GetterTree<State, any>> {
  studentTypeApiState: state => state.apiState,
  studentTypes: state => state.studentTypes.sort(byTitle),

}

const StudentTypeStore = {
  namespaced: true,
  state: new State(),
  mutations,
  actions,
  getters
};

export default StudentTypeStore;
