import type { GetterTree, MutationTree, ActionTree } from 'vuex';
import type { EnumDto } from '@/models/EnumDto';
import EnumRepository from '@/repositories/EnumRepository';
import APISTATE from '@/enums/APISTATE';

class State {
  schoolTermStatusApiState: Number = APISTATE.INIT;
  schoolTermStatusTypes: EnumDto[] = [];
  includeInAnnualManhoursApiState: Number = APISTATE.INIT;
  includeInAnnualManhours: EnumDto[] = [];
}

const mutations = <MutationTree<State>> {
  setSchoolTermStatusApiState(state, apiState: Number) {
    state.schoolTermStatusApiState = apiState;
  },
  setSchoolTermStatusTypes(state, schoolTermStatusTypes: EnumDto[]) {
    state.schoolTermStatusTypes = schoolTermStatusTypes;
  },
  setIncludeInAnnualManhoursApiState(state, apiState: Number) {
    state.includeInAnnualManhoursApiState = apiState;
  },
  setIncludeInAnnualManhours(state, includeInAnnualManhours: EnumDto[]) {
    state.includeInAnnualManhours = includeInAnnualManhours;
  }
};

const actions = <ActionTree<State, any>> {
  async loadSchoolTermStatusTypes({commit}) {
    commit('setSchoolTermStatusApiState', APISTATE.LOADING);
    try {
      const { data } = await EnumRepository.getSchoolTermStatusTypes();
      commit('setSchoolTermStatusTypes', data);
      commit('setSchoolTermStatusApiState', APISTATE.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setSchoolTermStatusApiState', APISTATE.ERROR);
    }
  },

  async loadIncludeInAnnualManhours({commit}) {
    commit('setIncludeInAnnualManhoursApiState', APISTATE.LOADING);
    try {
      const { data } = await EnumRepository.getIncludeInAnnualManhours();
      commit('setIncludeInAnnualManhours', data);
      commit('setIncludeInAnnualManhoursApiState', APISTATE.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setIncludeInAnnualManhoursApiState', APISTATE.ERROR);
    }
  },

};

const getters = <GetterTree<State, any>> {
  schoolTermStatusApiState: state => state.schoolTermStatusApiState,
  schoolTermStatusTypes: state => state.schoolTermStatusTypes,
  schoolTermStatusLabelByName: state => (name:string) => (name) ? state.schoolTermStatusTypes.find(q => q.name === name)?.label ?? '' : '',
  schoolTermStatusValueByName: state => (name:string) => (name) ? state.schoolTermStatusTypes.find(q => q.name === name)?.value ?? '' : '',
  schoolTermValidStatusTypes: state => (name:string) => state.schoolTermStatusTypes.filter(q => q.value >= state.schoolTermStatusTypes.find(w => w.name === name)?.value ?? 0),

  includeInAnnualManhoursApiState: state => state.includeInAnnualManhoursApiState,
  includeInAnnualManhours: state => state.includeInAnnualManhours,

};

const EnumStore = {
  namespaced: true,
  state: new State(),
  mutations,
  actions,
  getters
};

export default EnumStore;
