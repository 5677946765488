import PlanningClient from '@/clients/PlanningClient';
import { StudentTypeDto } from "@/models/planning/StudentTypeDto";
import { IStudentTypeRepository } from "../interfaces/planning/IStudentTypeRepository";

const resource = '/studentType';

export const StudentTypeRepository: IStudentTypeRepository = {
  getAll: () => 
    PlanningClient.get(`${resource}`) as Promise<{data:StudentTypeDto[]}>,
}
