





















import Vue from "vue";

const allowedIcons = [
  "caret-circle-up",
  "caret-circle-down",
];

const allowedTypes = [
  "danger",
  "success",
]

export default Vue.extend({
  name: "icon",
  props: {
    icon: {
      type: String,
      default: "",
      validator: prop => prop === "" || allowedIcons.includes(prop),
    },
    type: {
      type: String,
      default: "",
      validator: prop => prop === "" || allowedTypes.includes(prop),
    }
  }
})
