import { FinancedDto, PlanningViewDto } from "@/models/ConstantsDto"

const financedOptions:FinancedDto[] = [
  { value: true, title: 'Finansieret' },
  { value: false, title: 'Ikke finansieret' }
];

const planningViewOptions:PlanningViewDto[] = [
  { value: 'CLASSSUBJECT', title: 'Fagtilbud' },
  { value: 'PROJECT', title: 'Opgaver' }
];

export { 
  financedOptions,
  planningViewOptions,
  
};
