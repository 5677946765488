import { arrayReplaceWhere } from "@/utils/array";
import { teacherFilter } from '@/models/filterpanel/TeacherCriteriaModel';

import Repository from "@/repositories/RepositoryFactory";
import LoadingState from "../../enums/APISTATE";

const TeachersRepo = Repository.get("teachers");

const addArrayItem = (arrayObj, value, single = false) => {
  if (Array.isArray(arrayObj) && !single) {
    arrayObj.push(value);
  }
  else {
    arrayObj = [value];
  }
  return arrayObj;
}

const removeArrayItem = (arrayObj, value) => {
  arrayObj = arrayObj.filter((q) => q !== value);
  if (arrayObj.length < 1) {
    arrayObj = undefined;
  }
  return arrayObj;
}

const toLookup = (collection, keyFunc) => {
  const output = {};
  for (const item of collection) {
    const key = keyFunc(item);
    if (!Object.prototype.hasOwnProperty.call(output, key)) {
      output[key] = [];
    }
    output[key].push(item);
  }
  return output;
};

const initialsSort = (a,b) => {
  const aValue = `${a.initials}`.toUpperCase();
  const bValue = `${b.initials}`.toUpperCase();
  return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
} 

export default {
  namespaced: true,
  state: {
    selectedTeacher: null,
    state: LoadingState.INIT,
    teachers: [],
    filter: {
      departments: undefined,
      schoolTerms: undefined,
      schoolSubjects: undefined,
      skip: undefined,
      take: undefined,
    },
    teachersTotal: 0,
    teachersTaken: 0,
    teachersSkipped: 0,
    criteriaFilter: {},
    filteredTeachers: [],
    mappedTeachers: [],
  },

  mutations: {
    setSelected(state, teacher) {
      state.selectedTeacher = teacher;
    },
    setState: (state, loadingState) => {
      state.state = loadingState;
    },
    setTeachers: (state, teachers) => {
      state.teachers = teachers;
    },
    addTeacher: (state, teacher) => {
      state.teachers.push(teacher);
    },
    updateTeacher: (state, teacher) => {
      state.teachers = arrayReplaceWhere(
        state.teachers, 
        teacher, 
        t => t.id === teacher.id
      );
    },
    setFilteredResult(state, result) {
      state.teachers = result.data;
      state.teachersTotal = result.totalRecords;
      state.teachersTaken = result.taken;
      state.teachersSkipped = result.skipped;
    },
    setFilterProperty(state, {departments, schoolTerms, schoolSubjects, skip, take}) {
      if (departments) state.filter.departments = addArrayItem(state.filter.departments, departments);
      if (schoolTerms) state.filter.schoolTerms = addArrayItem(state.filter.schoolTerms, schoolTerms, true);
      if (schoolSubjects) state.filter.schoolSubjects = addArrayItem(state.filter.schoolSubjects, schoolSubjects);
      if (skip) state.filter.skip = skip;
      if (take) state.filter.take = take;
    },
    clearFilterProperty(state, {departments, schoolTerms, schoolSubjects, skip, take}) {
      if (departments) state.filter.departments = removeArrayItem(state.filter.departments, departments);
      if (schoolTerms) state.filter.schoolTerms = removeArrayItem(state.filter.schoolTerms, schoolTerms);
      if (schoolSubjects) state.filter.schoolSubjects = removeArrayItem(state.filter.schoolSubjects, schoolSubjects);
      if (skip) state.filter.skip = undefined;
      if (take) state.filter.take = undefined;
    },
    resetFilter(state) {
      state.filter = {
        departments: undefined,
        schoolTerms: undefined,
        schoolSubjects: undefined,
        skip: undefined,
        take: undefined
      };
    },
    resetData(state) {
      state.teachers = [];
      state.teachersTotal = 0;
      state.teachersTaken = 0;
      state.teachersSkipped = 0;
      state.selectedTeacher = null;
    },
    setCriteriaFilter(state, criteriaFilter) {
      state.criteriaFilter = criteriaFilter;
    },
    setFilteredTeachers(state) {
      const filteredTeachers = teacherFilter(state.teachers, state.criteriaFilter).sort(initialsSort);      
      
      for (const teacher of filteredTeachers)
        teacher.title = (
          function getTitle(teacher) {
            const initials = `${teacher.initials}`.toUpperCase();
            const name = `${teacher.firstName} ${teacher.lastName}`;
            return `${initials} - ${name}`;
          }
        )(teacher);

      state.filteredTeachers = filteredTeachers;
    },
    setMappedTeachers(state, mappedTeachers) {
      state.mappedTeachers = mappedTeachers;
    }
  },

  actions: {
    setSelectedTeacher({ commit }, teacher) {
      commit("setSelected", teacher);
    },

    async loadTeachers({ commit, state }) {
      commit("setState", LoadingState.LOADING);
      try {
        const teachersResponse = await TeachersRepo.getFiltered(state.filter.departments, state.filter.schoolTerms, state.filter.schoolSubjects, state.filter.skip, state.filter.take);

        commit("setFilteredResult", teachersResponse.data);
        commit("setState", LoadingState.LOADED);
      }
      catch (error) {
        console.error(error);
        commit("setState", LoadingState.ERROR);
      }
    },

    async loadTeachersBySchoolTerm({commit}, schoolTermId) {
      commit("setState", LoadingState.LOADING);
      try {
        const teachersResponse = await TeachersRepo.getFiltered(undefined, [schoolTermId], undefined, undefined, undefined);

        commit("setFilteredResult", teachersResponse.data);
        commit("setState", LoadingState.LOADED);
      }
      catch (error) {
        console.error(error);
        commit("setState", LoadingState.ERROR);
      }
    },

    async attachTeachersTermsAndSubjects({commit, dispatch, rootState, state}) {
      commit("setState", LoadingState.LOADING);
      try {
        if (rootState.teacherSubjectsStore.teacherSubjects.length === 0) {
          await dispatch("teacherSubjectsStore/loadAllTeacherSubjects", undefined, {root:true});
        }
        if (rootState.teacherTermsStore.teacherTerms.length === 0) {
          await dispatch("teacherTermsStore/loadAllTeacherTerms", undefined, {root:true});
        }

        const teacherSubjectLookup = toLookup(rootState.teacherSubjectsStore.teacherSubjects, q => q.relTeacher);
        const teacherTermLookup = toLookup(rootState.teacherTermsStore.teacherTerms, q => q.relTeacher);

        for (const teacher of state.teachers) {
          if (Object.prototype.hasOwnProperty.call(teacherSubjectLookup, teacher.id)) {
            teacher.teacherSubjects = teacherSubjectLookup[teacher.id];
          }
          else {
            teacher.teacherSubjects = [];
          }
          if (Object.prototype.hasOwnProperty.call(teacherTermLookup, teacher.id)) {
            teacher.teacherTerms = teacherTermLookup[teacher.id];
          }
          else {
            teacher.teacherTerms = [];
          }
        }

        commit("setState", LoadingState.LOADED);
      }
      catch(error) {
        console.error(error);
        commit("setState", LoadingState.ERROR);
      }

    },

    async createTeacher({ commit }, teacher) {
      commit("setState", LoadingState.LOADING);
      try {
        const createdTeacher = await TeachersRepo.create(teacher);
        commit("addTeacher", createdTeacher.data);
        commit("setState", LoadingState.LOADED);
      } catch(error) {
        console.error(error);
        commit("setState", LoadingState.ERROR);
      }
    },

    async updateTeacher({ commit }, teacher) {
      commit("setState", LoadingState.LOADING);
      try {
        const createdTeacher = await TeachersRepo.update(teacher);
        commit("updateTeacher", createdTeacher.data);
        commit("setState", LoadingState.LOADED);
      } catch(error) {
        console.error(error);
        commit("setState", LoadingState.ERROR);
      }
    },

    async setFilterProperty({commit, dispatch}, {departments, schoolTerms, schoolSubjects, skip, take}) {
      commit('setFilterProperty', {departments, schoolTerms, schoolSubjects, skip, take});
      await dispatch('loadTeachers');
    },
  
    async clearFilterProperty({commit, dispatch}, {departments, schoolTerms, schoolSubjects, skip, take}) {
      commit('clearFilterProperty', {departments, schoolTerms, schoolSubjects, skip, take});
      await dispatch('loadTeachers');
    },
  
    resetFilters({commit}) {
      commit('resetFilter')
    },

    resetTeacherStore({commit}) {
      commit('resetData');
    },
    filterTeachers({commit, state}, criteriaFilter) {
      commit("setCriteriaFilter", criteriaFilter ?? state.criteriaFilter);
      commit("setFilteredTeachers");
      commit("setMappedTeachers");
    }
  },
  getters: {
    selectedTeacher(state) {
      return state.selectedTeacher;
    },
    state(state) {
      return state.state;
    },
    teachers(state) {
      return state.teachers.sort(initialsSort);
    },
    isLoading(state) {
      return state.state === LoadingState.LOADING;
    },
    getTeacher(state) {
      return id => state.teachers.find(t => t.id === id);
    },
    filteredTeachers: state => state.filteredTeachers,
  }
}
