import client from "@/clients/PlanningClient";
import { EducationalDirectionDto } from "@/models/planning/EducationalDirectionDto";

const resource = '/EducationalDirection';

export const EducationalDirectionRepository = {
  getAll: () => client.get(`${resource}`) as Promise<{data: EducationalDirectionDto[]}>,
  getByKey: (key:string) => client.get(`${resource}/${key}`) as Promise<{data:EducationalDirectionDto[]}>,

}
