import type { GetterTree, MutationTree, ActionTree } from 'vuex';
import { ClassSubjectDto } from '@/models/planning/ClassSubjectDto';
import { ClassSubjectDtoResult } from '@/models/planning/ClassSubjectDtoResult';
import APISTATE from '@/enums/APISTATE';
import { ClassSubjectRepository } from "@/repositories/planning/ClassSubjectRepository";
import { Guid } from 'guid-typescript';
import { classSubjectFilter, ClassSubjectCriteriaModel } from '@/models/filterpanel/ClassSubjectCriteriaModel';
import { HubConnection } from '@microsoft/signalr';
import SignalRHubs from '@/repositories/SignalRHubs';
import Vue from 'vue';

type filter = {
  departments: number[] | undefined;
  schoolTerms: string[] | undefined;
  schoolSubjects: number[] | undefined;
  showDeactivated: boolean | undefined;
  skip: number | undefined;
  take: number | undefined;
}

const sortByClassIdThenSubject = (a:ClassSubjectDto, b:ClassSubjectDto) => {
  if (a.classId < b.classId) return -1;
  if (b.classId < a.classId) return 1;
  const aSubject = a.relSchoolSubjectNavigation?.title ?? '',
        bSubject = b.relSchoolSubjectNavigation?.title ?? '';
  if (aSubject < bSubject) return -1;
  if (bSubject < aSubject) return 1;
  return 0;
};

const addArrayItem = (arrayObj:any, value:any, single:boolean = false) => {
  if (Array.isArray(arrayObj) && !single) {
    arrayObj.push(value);
  }
  else {
    arrayObj = [value];
  }
  return arrayObj;
}

const removeArrayItem = (arrayObj:any, value:any) => {
  arrayObj = arrayObj.filter((q:any) => q !== value);
  if (arrayObj.length < 1) {
    arrayObj = undefined;
  }
  return arrayObj;
}

const classIdSort = (a:ClassSubjectDto,b:ClassSubjectDto) => {
  const aValue = `${a.classId}`.toUpperCase();
  const bValue = `${b.classId}`.toUpperCase();
  return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
};

class State {
  apiState: number = APISTATE.INIT;
  classSubject: ClassSubjectDto | {} = {};
  classSubjects: ClassSubjectDto[] = [];
  classSubjectsTotal: number = 0;
  classSubjectsTaken: number = 0;
  classSubjectsSkipped: number = 0;
  filter: filter = {
    departments: undefined,
    schoolTerms: undefined,
    schoolSubjects: undefined,
    showDeactivated: undefined,
    skip: undefined,
    take: undefined,
  };
  classSubjectDeletable: boolean|undefined = undefined;
  connection: HubConnection = SignalRHubs.dataUpdateHub();
  criteriaFilter = {} as ClassSubjectCriteriaModel; 
  filteredClassSubjects = [] as ClassSubjectDto[];
  mappedClassSubjects = [] as unknown[];
}

const mutations = <MutationTree<State>> {
  setApiState(state, apiState: number) {
    state.apiState = apiState;
  },
  async checkHubConnection(state, myUserId:Guid) {
    if (state.connection.state === "Disconnected") {
      await state.connection.start();
      state.connection.on("ClassSubject", (action, modelString, sourceUserId) => {
        console.log(['dataUpdate',action,'classSubject']);
        const model:ClassSubjectDto = JSON.parse(modelString);
        const existingIndex = state.classSubjects.findIndex(q => q.id === model.id);
        if (existingIndex > -1) {
          state.classSubjects[existingIndex] = {...model};
        }
        Vue.set(state, 'classSubjects', [...state.classSubjects]);
        if (Object.prototype.hasOwnProperty.call(state.classSubject, 'id') && model.id === (state.classSubject as ClassSubjectDto).id) {
          state.classSubject = {...model};
        }
      });
    }
  },
  setClassSubject(state, classSubject: ClassSubjectDto) {
    state.classSubject = classSubject;
  },
  setClassSubjects(state, classSubjects: ClassSubjectDto[]) {
    state.classSubjects = classSubjects;
  },
  setClassSubjectResult(state, result: ClassSubjectDtoResult) {
    state.classSubjects = result.data;
    state.classSubjectsTotal = result.totalRecords;
    state.classSubjectsTaken = result.taken;
    state.classSubjectsSkipped = result.skipped;
  },
  setClassSubjectDeletable(state, deletable:boolean) {
    state.classSubjectDeletable = deletable;
  },
  setFilterProperty(state, {departments, schoolTerms, schoolSubjects, showDeactivated, skip, take}) {
    if (departments) state.filter.departments = addArrayItem(state.filter.departments, departments);
    if (schoolTerms) state.filter.schoolTerms = addArrayItem(state.filter.schoolTerms, schoolTerms, true);
    if (schoolSubjects) state.filter.schoolSubjects = addArrayItem(state.filter.schoolSubjects, schoolSubjects);
    if (showDeactivated) state.filter.showDeactivated = showDeactivated;
    if (skip) state.filter.skip = skip;
    if (take) state.filter.take = take;
  },
  clearFilterProperty(state, {departments, schoolTerms, schoolSubjects, showDeactivated, skip, take}) {
    if (departments) state.filter.departments = removeArrayItem(state.filter.departments, departments);
    if (schoolTerms) state.filter.schoolTerms = removeArrayItem(state.filter.schoolTerms, schoolTerms);
    if (schoolSubjects) state.filter.schoolSubjects = removeArrayItem(state.filter.schoolSubjects, schoolSubjects);
    if (showDeactivated) state.filter.showDeactivated = showDeactivated;
    if (skip) state.filter.skip = undefined;
    if (take) state.filter.take = undefined;
  },
  resetFilter(state) {
    state.filter = {
      departments: undefined,
      schoolTerms: undefined,
      schoolSubjects: undefined,
      showDeactivated: undefined,
      skip: undefined,
      take: undefined
    };
  },
  resetData(state) {
    state.classSubject = {};
    state.classSubjects = [];
    state.classSubjectsTotal = 0;
    state.classSubjectsTaken = 0;
    state.classSubjectsSkipped = 0;
  },
  setCriteriaFilter(state, filter: ClassSubjectCriteriaModel) {
    state.criteriaFilter = filter;
  },
  setFilteredClassSubjects(state, filteredClassSubjects: ClassSubjectDto[]) {
    state.filteredClassSubjects = classSubjectFilter(state.classSubjects, state.criteriaFilter).sort(classIdSort);    
  },
  mapClassSubjects(state, filteredClassSubjects: ClassSubjectDto[]) {
    const output = {};

    for (const classSubject of state.filteredClassSubjects) {
      const subjectTitle = classSubject.relSchoolSubjectNavigation.title;
      if (!Object.prototype.hasOwnProperty.call(output, subjectTitle)) {
        output[subjectTitle] = { label: subjectTitle, entries: [] };
      }
      if (!classSubject.relMergedIntoClassSubject) { // hide merged classSubjects
        output[subjectTitle].entries.push(classSubject);
      }
      output[subjectTitle].entries.sort(
        (a:ClassSubjectDto,b:ClassSubjectDto) => { 
          const aValue = `${a.classId}`.toUpperCase();
          const bValue = `${b.classId}`.toUpperCase();
          return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
        }
      );

      for (const entry of output[subjectTitle].entries) {
        entry.title = (
          (subject:ClassSubjectDto) => {
            const merged = ((classSubjectId:Guid):ClassSubjectDto[] => state.classSubjects.filter(q => q.relMergedIntoClassSubject === classSubjectId).sort(classIdSort))(subject.id);
            if (Array.isArray(merged) && merged.length > 0) {
              return `${subject.classId} (${merged.map(q => q.classId).join(', ')})`;
            }
            return subject.classId;
          }
        )(entry);
      }
    }

    const result = Object.values(output).sort((a:any,b:any) => a.label.toUpperCase() > b.label.toUpperCase() ? 1 : a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 0 );

    state.mappedClassSubjects = result;    
  }
}

const actions = <ActionTree<State, any>> {
  async loadClassSubject({commit, rootGetters}, classSubjectId:Guid) {
    commit('setApiState', APISTATE.LOADING);
    try {
      if (classSubjectId) {
        const data = await Promise.all([
          ClassSubjectRepository.getOne(classSubjectId),
          ClassSubjectRepository.isDeletable(classSubjectId)
        ]);
        commit('setClassSubject', data[0].data);
        commit('setClassSubjectDeletable', data[1].data);
        commit('checkHubConnection', rootGetters['currentUserId']);
      }
      else {
        commit('setClassSubject', {});
      }
      commit('setApiState', APISTATE.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
    }
  },

  async loadClassSubjects({commit, state, rootGetters}) {
    commit('setApiState', APISTATE.LOADING);
    try {
      const { data } = await ClassSubjectRepository.getFiltered(state.filter.departments, state.filter.schoolTerms, state.filter.schoolSubjects, state.filter.showDeactivated, state.filter.skip, state.filter.take);
      data.data.sort(sortByClassIdThenSubject);
      commit('setClassSubjectResult', data);
      commit('checkHubConnection', rootGetters['currentUserId']);
      commit('setApiState', APISTATE.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
    }
  },

  async loadClassSubjectsBySchoolTerm({commit, rootGetters}, schoolTermId:string) {
    commit('setApiState', APISTATE.LOADING);
    try {
      const { data } = await ClassSubjectRepository.getFiltered(undefined, [schoolTermId], undefined, false, undefined, undefined);
      data.data.sort(sortByClassIdThenSubject);
      commit('setClassSubjectResult', data);
      commit('checkHubConnection', rootGetters['currentUserId']);
      commit('setApiState', APISTATE.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
    }
  },

  async setFilterProperty({commit, dispatch}, {departments, schoolTerms, schoolSubjects, skip, take}) {
    commit('setFilterProperty', {departments, schoolTerms, schoolSubjects, skip, take});
    await dispatch('loadClassSubjects');
  },

  async clearFilterProperty({commit, dispatch}, {departments, schoolTerms, schoolSubjects, skip, take}) {
    commit('clearFilterProperty', {departments, schoolTerms, schoolSubjects, skip, take});
    await dispatch('loadClassSubjects');
  },

  resetFilters({commit}) {
    commit('resetFilter')
  },

  async createClassSubject({commit, dispatch}, formClassSubject) {
    commit('setApiState', APISTATE.LOADING);
    try {
      const { data } = await ClassSubjectRepository.create(formClassSubject);
      await dispatch('loadClassSubject', data.id);
      return data.id;
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
      throw error;
    }
  },

  async updateClassSubject({commit, dispatch}, formClassSubject) {
    commit('setApiState', APISTATE.LOADING);
    try {
      const { data } = await ClassSubjectRepository.update(formClassSubject);
      await dispatch('loadClassSubject', data.id);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
      throw error;
    }
  },

  async removeClassSubject({commit, dispatch}, classSubjectId:Guid) {
    commit('setApiState', APISTATE.LOADING);
    try {
      await ClassSubjectRepository.remove(classSubjectId);
      commit('setApiState', APISTATE.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
      throw error;
    }
  },

  async deactivateClassSubject({commit, dispatch}, classSubjectId:Guid) {
    commit('setApiState', APISTATE.LOADING);
    try {
      await ClassSubjectRepository.deactivate(classSubjectId);
      await dispatch('loadClassSubject', classSubjectId);
      commit('setApiState', APISTATE.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
      throw error;
    }
  },

  async activateClassSubject({commit, dispatch}, classSubjectId:Guid) {
    commit('setApiState', APISTATE.LOADING);
    try {
      await ClassSubjectRepository.activate(classSubjectId);
      await dispatch('loadClassSubject', classSubjectId);
      commit('setApiState', APISTATE.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
      throw error;
    }
  },

  resetClassSubjectStore({commit}) {
    commit('resetData');
  },
  
  filterClassSubjects({commit, state}, filter: ClassSubjectCriteriaModel) {    
    commit('setCriteriaFilter', filter ?? state.criteriaFilter)
    commit('setFilteredClassSubjects');
    commit('mapClassSubjects');
  }
}

// Builds list of class-subject entries that qualify as merge candidates
const getMergeCandidates = (classSubject:ClassSubjectDto, state:State) => {
  const output = [] as ClassSubjectDto[];

  output.push({
    id: null,
    classId: 'Ikke samlæst'
  } as ClassSubjectDto);

  if (!classSubject) return output;
  if (!classSubject.relSchoolTerm) return output
  if (!classSubject.relSchoolSubject) return output;

  state.classSubjects
    .filter(q => 
      !q.relMergedIntoClassSubject 
      && q.relSchoolSubject == classSubject.relSchoolSubject 
      && q.relSchoolTerm == classSubject.relSchoolTerm 
      && q.id !== classSubject.id)
    .forEach(q => output.push(q));

  const current = state.classSubjects.find(q => q.id == classSubject.relMergedIntoClassSubject);
  if (current && !output.includes(current)) {
    output.push(current);
  }

  return output;
};



const getters = <GetterTree<State, any>> {
  classSubjectApiState: state => state.apiState,
  classSubjects: state => state.classSubjects.sort(classIdSort),
  classSubject: state => state.classSubject,
  classSubjectMergeCandidates: state => (classSubject:ClassSubjectDto) => getMergeCandidates(classSubject, state).sort(classIdSort),
  classSubjectMergedClasses: (state) => (classSubjectId:Guid):ClassSubjectDto[] => state.classSubjects.filter(q => q.relMergedIntoClassSubject === classSubjectId).sort(classIdSort),
  isClassSubjectDeletable: state => state.classSubjectDeletable,
  filteredClassSubjects: state => state.filteredClassSubjects,
  mappedClassSubjects: state => state.mappedClassSubjects,
  classSubjectHoursForPlanning: (state) => (classSubjectId:Guid) => {
    const classSubject = state.classSubjects.find((q:ClassSubjectDto) => q.id === classSubjectId);
    let result = undefined;
    if (classSubject?.relDepartment) {
      result = classSubject.relDepartmentNavigation.remote
        ? classSubject.numberOfStudentsExpected * classSubject.remoteStudentsToHoursFactor
        : classSubject.budgetPlanningHours * classSubject.subjectPlanningHoursFactor;
    }
    return isNaN(result) ? undefined : result;
  },

}

const ClassSubjectStore = {
  namespaced: true,
  state: new State(),
  mutations,
  actions,
  getters
};

export default ClassSubjectStore;
