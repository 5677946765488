import client from "@/clients/PlanningClient";
import { TeacherDto } from "@/models/planning/TeacherDto";
import { TeacherDtoResult } from "@/models/planning/TeacherDtoResult";
import qs from 'qs';

const resource = '/teachers';

export const TeachersRepository = {
  getAll: () => client.get(`${resource}`) as Promise<{data: TeacherDto[]}>,
  create: (teacher: TeacherDto) => client.post(`${resource}`, teacher) as Promise<TeacherDto>,
  update: (teacher: TeacherDto) => client.put(`${resource}`, teacher) as Promise<TeacherDto>,

  getFiltered: (departments:number[]|undefined, schoolTerms:string[]|undefined, schoolSubjects:number[]|undefined, skip:number|undefined, take:number|undefined) =>
    client.get(
      `${resource}/filtered`, {
        params: {
          departments: departments,
          schoolTerms: schoolTerms,
          schoolSubjects: schoolSubjects,
          skip: skip ?? '',
          take: take ?? ''
        },
        paramsSerializer: params => qs.stringify(params), // to format array parameters in a way that .net core will pick up by default

    }) as Promise<{data:TeacherDtoResult}>,

}
