import { ClassSubjectDto } from "../planning/ClassSubjectDto";
import { ProjectsDto } from "../planning/ProjectsDto";
import { TeacherDto } from "../planning/TeacherDto";

type DragItemInfo = {
  type: string;
  value: Object;

  canDrop(target: DragItemInfo): Boolean;
}

class ClassSubjectDragInfo implements DragItemInfo {
  type: string;
  value: Object;

  constructor(source: ClassSubjectDto) {
    this.type = 'classsubject';
    this.value = source;
  }

  canDrop(target: DragItemInfo) {
    return target && target.type !== this.type;
  }
}

class TeacherDragInfo implements DragItemInfo {
  type: string;
  value: Object;

  constructor(source: TeacherDto) {
    this.type = 'teacher';
    this.value = source;
  }

  canDrop(target: DragItemInfo) {
    return target && target.type !== this.type;
  }
}

class ProjectDragInfo implements DragItemInfo {
  type: string;
  value: Object;

  constructor(source: ProjectsDto) {
    this.type = 'project';
    this.value = source;
  }

  canDrop(target: DragItemInfo) {
    return target && target.type !== this.type;
  }
}

export { DragItemInfo, ClassSubjectDragInfo, TeacherDragInfo, ProjectDragInfo }
