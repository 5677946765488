import { ClassSubjectDto } from "../planning/ClassSubjectDto";

type ClassSubjectCriteriaModel = {
  classSubjects?: string[] | undefined;
  departments?: number[] | undefined;
  educationalDirections?: number[] | undefined;
  schoolSubjects?: number[] | undefined;
}

const defaultClassSubjectCriteriaModel = {
  classSubjects: undefined,
  departments: undefined,
  educationalDirections: undefined,
  schoolSubjects: undefined
} as ClassSubjectCriteriaModel;

const classSubjectFilter = (collection:ClassSubjectDto[], filter:ClassSubjectCriteriaModel) => 
  collection.filter((q: ClassSubjectDto) => {
    let keep = true;
    if (filter.classSubjects) {
      keep &&= filter.classSubjects.includes(q.classId);
    }
    if (filter.departments) {
      keep &&= filter.departments.includes(q.relDepartment);
    }
    if (filter.educationalDirections) {
      keep &&= filter.educationalDirections.includes(q.relEducationalDirection);
    }
    if (filter.schoolSubjects) {
      keep &&= filter.schoolSubjects.includes(q.relSchoolSubject);
    }
    return keep;
  });

export {
  ClassSubjectCriteriaModel,
  classSubjectFilter,
  defaultClassSubjectCriteriaModel
}
