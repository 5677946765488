import Vue from 'vue'
import Vuex from 'vuex'
import { vuexOidcCreateStoreModule } from 'vuex-oidc';
import { oidcSettings } from '../config/oidc';
import jwt from 'jsonwebtoken';

import appUsers from './modules/AppUsersStore';
import classSubjectStore from './modules/planning/ClassSubjectStore';
import classSubjectStudentTypeStore from './modules/planning/ClassSubjectStudentTypeStore';
import constantsStore from './modules/ConstantsStore';
import dashboardStore from './modules/planning/DashboardStore';
import departments from "./modules/DepartmentsStore";
import distributionStore from './modules/planning/DistributionStore';
import dragAndDropStore from './modules/DragAndDropStore';
import educationDirectionsStore from './modules/educationDirectionsStore';
import employmentTypesStore from './modules/employmentTypesStore';
import enumStore from './modules/EnumStore';
import projectsStore from './modules/planning/ProjectsStore';
import projectTypeStore from './modules/planning/ProjectTypeStore';
import schoolSubjectsStore from './modules/planning/SchoolSubjectsStore';
import schoolTermStore from './modules/planning/SchoolTermStore';
import sidebarMenuStore from './modules/SidebarMenuStore';
import studentTypeStore from './modules/planning/StudentTypeStore';
import teachers from './modules/TeachersStore';
import teacherSubjectsStore from './modules/planning/TeacherSubjectsStore';
import teacherTermsStore from './modules/planning/TeacherTermsStore';
import validationStore from './modules/ValidationStore';

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    currentUser: {},
    pageTitle: 'VUC Storstrøm',
    siteVersion: 'v1.0 - build: 120',
    globalSchoolTermSelection: undefined,

  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('globalSchoolTermSelection')) {
        state.globalSchoolTermSelection = localStorage.getItem('globalSchoolTermSelection');
      }
    },
    setCurrentUser(state, userInfo) {
      state.currentUser = userInfo;
    },
    setPageTitle(state, pageTitle) {
      state.pageTitle = pageTitle;
    },
    setGlobalSchoolTermSelection(state, schoolTermId) {
      state.globalSchoolTermSelection = schoolTermId;
      localStorage.setItem('globalSchoolTermSelection', schoolTermId);
    }

  },
  actions: {
    userLoaded({ commit }, user) {
      const decoded = jwt.decode(user.access_token);
      commit('setCurrentUser', { name: decoded.Name, role: decoded.Role, email: decoded.Email, id: decoded.sub });
    },
    userUnloaded({ commit }) {
      commit('setCurrentUser', { name: 'Not logged in', role: '', email: '', id: '' });
    },
    updatePageTitle({commit}, pageTitle) {
      commit('setPageTitle', pageTitle);
    },
    updateGlobalSchoolTermSelection({commit}, schoolTermId) {
      commit('setGlobalSchoolTermSelection', schoolTermId);
    },

  },
  getters: {
    currentUser: (state) => state.currentUser,
    pageTitle: state => state.pageTitle,
    siteVersion: state => state.siteVersion,

    // Administrator state of the currently logged-in user, regardless of impersonation
    isAdmin: (state) => state.currentUser && state.currentUser.role === "Admin",

    // ID of the currently logged-in user. Undefined if anonymous.
    currentUserId: state => (state.currentUser) ? state.currentUser.id : undefined,

    globalSchoolTermSelection: (state) => state.globalSchoolTermSelection,
    
  },
  modules: {
    appUsers,
    classSubjectStore,
    classSubjectStudentTypeStore,
    constantsStore,
    dashboardStore,
    departments,
    distributionStore,
    dragAndDropStore,
    educationDirectionsStore,
    employmentTypesStore,
    enumStore,
    projectsStore,
    projectTypeStore,
    schoolSubjectsStore,
    schoolTermStore,
    sidebarMenuStore,
    studentTypeStore,
    teachers,
    teacherSubjectsStore,
    teacherTermsStore,
    validationStore,
    oidcStore: vuexOidcCreateStoreModule(
      oidcSettings,
      // ...
      {
        namespaced: true,
        dispatchEventsOnWindow: true
      },
      // OIDC Event listeners
      {
        userLoaded: (user) => store.dispatch('userLoaded', user),
        userUnloaded: () => store.dispatch('userUnloaded'),
        accessTokenExpiring: () => console.log('Access token will expire'),
        accessTokenExpired: () => console.log('Access token did expire'),
        silentRenewError: () => console.log('OIDC user is unloaded'),
        userSignedOut: () => console.log('OIDC user is signed out'),
        oidcError: (payload) => console.log('OIDC error', payload),
        automaticSilentRenewError: (payload) => console.log('OIDC automaticSilentRenewError', payload)
      }
    ),
  }
})

store.subscribe((mutation, state) => {
  switch (mutation.type) {
    case 'setGlobalSchoolTermSelection':
      localStorage.setItem('globalSchoolTermSelection', mutation.payload); break;
    case 'teachers/setFilterProperty':
    case 'classSubjectStore/setFilterProperty':
    case 'projectsStore/setFilterProperty':
      if (mutation.payload.schoolTerms) localStorage.setItem('globalSchoolTermSelection', mutation.payload.schoolTerms);
      state.globalSchoolTermSelection = mutation.payload.schoolTerms;
      break;
  }
});

export default store;
