export default {
  namespaced: true,
  state: {
    open: false,
  },
  mutations: {
    setOpen(state, isOpen) {
      state.open = isOpen;
    }
  },
  actions: {
    openSidebarMenu({commit}) {
      commit('setOpen', true);
    },
    sidebarMenuClosed({commit}) {
      commit('setOpen', false);
    }
  },
  getters: {
    sidebarMenuOpen: state => state.open,
  }
}