import AppUsersRepository from "./AppUsersRepository";
import { TeachersRepository } from "./planning/TeachersRepository";
import { DepartmentRepository } from "./planning/DepartmentRepository"
import { EmploymentTypeRepository } from "./planning/EmploymentTypeRepository";
import { EducationalDirectionRepository } from "./planning/EducationalDirectionRepository";

const repositories = {
  "appUsers": AppUsersRepository,
  "departments": DepartmentRepository,
  "teachers": TeachersRepository,
  "employmentTypes": EmploymentTypeRepository,
  "educationalDirections": EducationalDirectionRepository,
};

export type availableRepository = keyof typeof repositories;

export default {
  get: (name: availableRepository) => repositories[name]
};
