// Pro icons
import { 
  faCheck, 
  faCheckCircle, 
  faInfoCircle, 
  faExclamationTriangle, 
  faExclamationCircle,
  faArrowUp, 
  faAngleRight, 
  faAngleLeft, 
  faAngleDown, 
  faPlus,       
  faEye, 
  faEyeSlash, 
  faCaretDown, 
  faCaretUp, 
  faUpload, 
  faEnvelope, 
  faLock, 
  faChevronRight,
  faCaretCircleDown, 
  faCaretCircleUp,
  faTextHeight,
  faClose
} from "@fortawesome/pro-solid-svg-icons";

// Export them all here
export const icons = [
  faCheck, 
  faCheckCircle, 
  faInfoCircle, 
  faExclamationTriangle, 
  faExclamationCircle,
  faArrowUp, 
  faAngleRight, 
  faAngleLeft, 
  faAngleDown, 
  faPlus,       
  faEye, 
  faEyeSlash, 
  faCaretDown, 
  faCaretUp, 
  faUpload, 
  faEnvelope, 
  faLock, 
  faChevronRight,
  faCaretCircleDown, 
  faCaretCircleUp,
  faTextHeight,
  faClose
];