import PlanningClient from '@/clients/PlanningClient';
import { ITeacherSubjectRepository } from '../interfaces/planning/ITeacherSubjectRepository';
import { Guid } from "guid-typescript";
import { TeacherSubjectDto } from "@/models/planning/TeacherSubjectDto";

const resource = '/teachersubject';

export const TeacherSubjectRepository: ITeacherSubjectRepository = {
  getAllForTeacher: (teacherId:Guid) => PlanningClient.get(`${resource}/getAllForTeacher/${teacherId}`) as Promise<{data:TeacherSubjectDto[]}>,
  getOne: (teacherId:Guid) => PlanningClient.get(`${resource}/${teacherId}`) as Promise<{data:TeacherSubjectDto}>,
  create: (teacherSubject:TeacherSubjectDto) => PlanningClient.post(`${resource}`, teacherSubject) as Promise<{data:TeacherSubjectDto}>,
  getAll: () => PlanningClient.get(`${resource}`) as Promise<{data:TeacherSubjectDto[]}>,
  remove: (relTeacher:Guid,relSchoolSubject:number,relEducationalDirection:number) => PlanningClient.delete(`${resource}/${relTeacher}/${relSchoolSubject}/${relEducationalDirection}`),
  
}
