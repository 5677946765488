export const projects = [
  {
    path: '/projects',
    name: 'Projects',
    component: () => import('@/views/projects/Projects.vue')
  },
  {
    path: '/project/new',
    name: 'NewProject',
    component: () => import('@/views/projects/Project.vue')
  },
  {
    path: '/project/:projectId',
    name: 'EditProject',
    props: true,
    component: () => import('@/views/projects/Project.vue')
  }
];
