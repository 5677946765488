import PlanningClient from '@/clients/PlanningClient';
import { DashboardViewModel } from '@/models/dashboard/DashboardViewModel';
import { GroupResult } from '@/models/dashboard/GroupResult';
import qs from 'qs';

const resource = '/dashboard';

export const DashboardRepository = {
  getTabGroupOptions: () => PlanningClient.get(`${resource}/getTabGroupOptions`) as Promise<{data:any}>,

  classSubjectAverage: (viewModel:DashboardViewModel) => PlanningClient.get(`${resource}/classSubjectAverage/${viewModel.schoolTerm}`, {
    params: {
      departments: viewModel.criteriaModel?.departments,
      educationalDirections: viewModel.criteriaModel?.educationalDirections,
      schoolSubjects: viewModel.criteriaModel?.schoolSubjects,
      groupBy: viewModel.groupBy
    },
    paramsSerializer: params => qs.stringify(params),
  }) as Promise<{data:GroupResult}>,

  lessonHoursAverage: (viewModel:DashboardViewModel) => PlanningClient.get(`${resource}/lessonHoursAverage/${viewModel.schoolTerm}`, {
    params: {
      departments: viewModel.criteriaModel?.departments,
      educationalDirections: viewModel.criteriaModel?.educationalDirections,
      schoolSubjects: viewModel.criteriaModel?.schoolSubjects,
      groupBy: viewModel.groupBy
    },
    paramsSerializer: params => qs.stringify(params),
  }) as Promise<{data:GroupResult}>,

  annualManhoursAverage: (viewModel:DashboardViewModel) => PlanningClient.get(`${resource}/annualManhoursAverage/${viewModel.schoolTerm}`, {
    params: {
      departments: viewModel.criteriaModel?.departments,
      educationalDirections: viewModel.criteriaModel?.educationalDirections,
      schoolSubjects: viewModel.criteriaModel?.schoolSubjects,
      groupBy: viewModel.groupBy
    },
    paramsSerializer: params => qs.stringify(params),
  }) as Promise<{data:GroupResult}>,

}
