import PlanningClient from '@/clients/PlanningClient';
import { TeacherTermDto } from '@/models/planning/TeacherTermDto';
import { Guid } from 'guid-typescript';

const resource = '/TeacherTerm';

export const TeacherTermsRepository = {
  async getForTeacher(id: Guid) {
    return await PlanningClient.get(`${resource}/${id}`);
  },
  async post(teacherTerms: TeacherTermDto) {
    return await PlanningClient.post(`${resource}`, teacherTerms);
  },
  getAll: () => PlanningClient.get(`${resource}`) as Promise<{data:TeacherTermDto[]}>,
  getForSchoolTerm: (schoolTermId:string) => PlanningClient.get(`${resource}/bySchoolTerm/${schoolTermId}`) as Promise<{data:TeacherTermDto}>,
  put: (teacherTerms:TeacherTermDto) => PlanningClient.put(`${resource}`, teacherTerms) as Promise<{data:TeacherTermDto}>,
  remove: (teacherTerm:TeacherTermDto) => PlanningClient.delete(`${resource}/${teacherTerm.relTeacher}/${teacherTerm.relSchoolTerm}`) as Promise<void>,

}
