import { TeacherOnProjectAllocationDto } from "@/models/planning/TeacherOnProjectAllocationDto";
import { TeacherOnProjectAllocationDtoResult } from "@/models/planning/TeacherOnProjectAllocationDtoResult";
import { TeacherOnSubjectAllocationDto } from "@/models/planning/TeacherOnSubjectAllocationDto";
import { TeacherOnSubjectAllocationDtoResult } from "@/models/planning/TeacherOnSubjectAllocationDtoResult";
import { Guid } from "guid-typescript";
import Vue from 'vue';

type AllocationsType = {
  entries: [];
  count: number;
  totalHours: number;
}

type StateType = {
  activeSchoolTermId: string | undefined;
  teacherOnProjectAllocations: TeacherOnProjectAllocationDtoResult;
  teacherOnSubjectAllocations: TeacherOnSubjectAllocationDtoResult;
  classSubjectAllocations: AllocationsType;
  projectAllocations: AllocationsType;
  teacherAllocations: AllocationsType;
}



const findTeacherOnSubjectAllocation = (state:StateType,teacherId:Guid,classSubjectId:Guid) => state.teacherOnSubjectAllocations.data.find((q:TeacherOnSubjectAllocationDto) => q.relTeacher === teacherId && q.relClassSubject === classSubjectId);
const findTeacherOnProjectAllocation = (state:StateType,teacherId:Guid,projectId:Guid) => state.teacherOnProjectAllocations.data.find((q:TeacherOnProjectAllocationDto) => q.relTeacher === teacherId && q.relProject === projectId);



const removeTeacherOnSubjectAllocation = (state:StateType, teacherId:Guid, classSubjectId:Guid) => {
  const index = state.teacherOnSubjectAllocations.data.indexOf(findTeacherOnSubjectAllocation(state, teacherId, classSubjectId));
  state.teacherOnSubjectAllocations.data.splice(index, 1);
  state.teacherOnSubjectAllocations.totalRecords--;
}
const removeTeacherOnProjectAllocation = (state:StateType, teacherId:Guid, projectId:Guid) => {
  const index = state.teacherOnProjectAllocations.data.indexOf(findTeacherOnProjectAllocation(state, teacherId, projectId));
  state.teacherOnProjectAllocations.data.splice(index, 1);
  state.teacherOnProjectAllocations.totalRecords--;
}



const dataupdateTeacherOnSubjectAllocation = (state:StateType, model:TeacherOnSubjectAllocationDto, action:string) => {
  if (model.relClassSubjectNavigation.relSchoolTerm === state.activeSchoolTermId) {
    // Add or update teacherOnSubjectAllocation in state
    const existing = findTeacherOnSubjectAllocation(state, model.relTeacher, model.relClassSubject);
    switch (action) {
      case "add":
      case "update": {
        if (existing) {
          existing.hours = model.hours;
        }
        else if (action === "add") {
          state.teacherOnSubjectAllocations.data.push(model);
        }
      } break;
      case "delete": removeTeacherOnSubjectAllocation(state, existing.relTeacher, existing.relClassSubject); break;
    }
    // Add or update classSubjectAllocations and teacherAllocations in state
    switch (action) {
      case "add": {
        classSubjectAllocationsAdd(state, model);
        teacherClassSubjectAllocationsAdd(state, model);
      } break;
      case "update": {
        classSubjectAllocationsUpdate(state, model);
        teacherClassSubjectAllocationsUpdate(state, model);
      } break;
      case "delete": {
        classSubjectAllocationsDelete(state, model);
        teacherClassSubjectAllocationsDelete(state, model);
      } break;
    }
    calcTeacherAllocationStats(state);
    calcClassSubjectAllocationStats(state);
  }
}

const dataupdateTeacherOnProjectAllocation = (state:StateType, model:TeacherOnProjectAllocationDto, action:string) => {
  if (model.relProjectNavigation.relSchoolTerm === state.activeSchoolTermId) {
    // Add or update teacherOnProjectAllocation in state
    const existing = findTeacherOnProjectAllocation(state, model.relTeacher, model.relProject);
    switch (action) {
      case "add":
      case "update": {
        if (existing) {
          existing.hours = model.hours;
        }
        else if (action === "add") {
          state.teacherOnProjectAllocations.data.push(model);
        }
      } break;
      case "delete": removeTeacherOnProjectAllocation(state, existing.relTeacher, existing.relProject); break;
    }
    // Add or update projectAllocations and teacherAllocations in state
    switch (action) {
      case "add": {
        projectAllocationsAdd(state, model);
        teacherProjectAllocationsAdd(state, model);
      } break;
      case "update": {
        projectAllocationsUpdate(state, model);
        teacherProjectAllocationsUpdate(state, model);
      } break;
      case "delete": {
        projectAllocationsDelete(state, model);
        teacherProjectAllocationsDelete(state, model);
      } break;
    }
    calcTeacherAllocationStats(state);
    calcProjectAllocationStats(state);
  }
}



const projectAllocationsUpdate = (state:StateType, topa:TeacherOnProjectAllocationDto) => {
  const projectKey = `${topa.relProject}`;
  if (Object.prototype.hasOwnProperty.call(state.projectAllocations, projectKey)) {
    const entry = state.projectAllocations[projectKey].entries.find((q:TeacherOnProjectAllocationDto) => q.relTeacher === topa.relTeacher);
    if (entry) {
      Vue.set(entry, 'hours', topa.hours);
      Vue.set(entry, 'state', topa.state);
      return;
    }
  }
  projectAllocationsAdd(state, topa);
}
const projectAllocationsAdd = (state:StateType, topa:TeacherOnProjectAllocationDto) => {
  const projectKey = `${topa.relProject}`;
  if (!Object.prototype.hasOwnProperty.call(state.projectAllocations, projectKey)) {
    Vue.set(state.projectAllocations, projectKey, {entries:[]});
  }
  state.projectAllocations[projectKey].entries.push(topa);
  state.projectAllocations[projectKey].entries = state.projectAllocations[projectKey].entries.sort(
    (a:TeacherOnProjectAllocationDto,b:TeacherOnProjectAllocationDto) => a.relTeacherNavigation.initials > b.relTeacherNavigation.initials ? 1 : a.relTeacherNavigation.initials < b.relTeacherNavigation.initials ? -1 : 0
  );
}
const projectAllocationsDelete = (state:StateType, topa:TeacherOnProjectAllocationDto) => {
  const projectKey = `${topa.relProject}`;
  if (Object.prototype.hasOwnProperty.call(state.projectAllocations, projectKey)) {
    const index = state.projectAllocations[projectKey].entries.findIndex((q:TeacherOnProjectAllocationDto) => q.relTeacher === topa.relTeacher);
    if (index > -1) {
      state.projectAllocations[projectKey].entries.splice(index, 1);
    }
  }
}



const teacherProjectAllocationsUpdate = (state:StateType, topa:TeacherOnProjectAllocationDto) => {
  const teacherKey = `${topa.relTeacher}`;
  if (Object.prototype.hasOwnProperty.call(state.teacherAllocations, teacherKey)) {
    const entry = state.teacherAllocations[teacherKey].entries.find((q:TeacherOnProjectAllocationDto) => q.relProject === topa.relProject);
    if (entry) {
      Vue.set(entry, 'hours', topa.hours);
      Vue.set(entry, 'state', topa.state);
      return;
    }
  }
  teacherProjectAllocationsAdd(state, topa);
}
const teacherProjectAllocationsAdd = (state:StateType, topa:TeacherOnProjectAllocationDto) => {
  const teacherKey = `${topa.relTeacher}`;
  if (!Object.prototype.hasOwnProperty.call(state.teacherAllocations, teacherKey)) {
    Vue.set(state.teacherAllocations, teacherKey, {entries:[]});
  }
  state.teacherAllocations[teacherKey].entries.push(topa);
  state.teacherAllocations[teacherKey].entries.sort(
    (a:any,b:any) => {
      const aValue = a.relClassSubjectNavigation?.classId ?? a.relProjectNavigation.id;
      const bValue = b.relClassSubjectNavigation?.classId ?? b.relProjectNavigation.id;
      return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
    }
  );
}
const teacherProjectAllocationsDelete = (state:StateType, topa:TeacherOnProjectAllocationDto) => {
  const teacherKey = `${topa.relTeacher}`;
  if (Object.prototype.hasOwnProperty.call(state.teacherAllocations, teacherKey)) {
    const index = state.teacherAllocations[teacherKey].entries.findIndex((q:TeacherOnProjectAllocationDto) => q.relProject === topa.relProject);
    if (index > -1) {
      state.teacherAllocations[teacherKey].entries.splice(index, 1);
      return;
    }
  }
}



const classSubjectAllocationsUpdate = (state:StateType, tosa:TeacherOnSubjectAllocationDto) => {
  const subjectKey = `${tosa.relClassSubject}`;
  if (Object.prototype.hasOwnProperty.call(state.classSubjectAllocations, subjectKey)) {
    const entry = state.classSubjectAllocations[subjectKey].entries.find((q:TeacherOnSubjectAllocationDto) => q.relTeacher === tosa.relTeacher);
    if (entry) {
      Vue.set(entry, 'hours', tosa.hours);
      Vue.set(entry, 'state', tosa.state);
      return;
    }
  }
  classSubjectAllocationsAdd(state, tosa);
}
const classSubjectAllocationsAdd = (state:StateType, tosa:TeacherOnSubjectAllocationDto) => {
  const subjectKey = `${tosa.relClassSubject}`;
  if (!Object.prototype.hasOwnProperty.call(state.classSubjectAllocations, subjectKey)) {
    Vue.set(state.classSubjectAllocations, subjectKey, {entries:[]});
  }
  state.classSubjectAllocations[subjectKey].entries.push(tosa);
  state.classSubjectAllocations[subjectKey].entries = state.classSubjectAllocations[subjectKey].entries.sort(
    (a:TeacherOnSubjectAllocationDto,b:TeacherOnSubjectAllocationDto) => a.relTeacherNavigation.initials > b.relTeacherNavigation.initials ? 1 : a.relTeacherNavigation.initials < b.relTeacherNavigation.initials ? -1 : 0
  );
}
const classSubjectAllocationsDelete = (state:StateType, tosa:TeacherOnSubjectAllocationDto) => {
  const subjectKey = `${tosa.relClassSubject}`;
  if (Object.prototype.hasOwnProperty.call(state.classSubjectAllocations, subjectKey)) {
    const index = state.classSubjectAllocations[subjectKey].entries.findIndex((q:TeacherOnSubjectAllocationDto) => q.relTeacher === tosa.relTeacher);
    if (index > -1) {
      state.classSubjectAllocations[subjectKey].entries.splice(index, 1);
      return;
    }
  }
}



const teacherClassSubjectAllocationsUpdate = (state:StateType, tosa:TeacherOnSubjectAllocationDto) => {
  const teacherKey = `${tosa.relTeacher}`;
  if (Object.prototype.hasOwnProperty.call(state.teacherAllocations, teacherKey)) {
    const entry = state.teacherAllocations[teacherKey].entries.find((q:TeacherOnSubjectAllocationDto) => q.relClassSubject === tosa.relClassSubject);
    if (entry) {
      Vue.set(entry, 'hours', tosa.hours);
      Vue.set(entry, 'state', tosa.state);
      return;
    }
  }
  teacherClassSubjectAllocationsAdd(state, tosa);
}
const teacherClassSubjectAllocationsAdd = (state:StateType, tosa:TeacherOnSubjectAllocationDto) => {
  const teacherKey = `${tosa.relTeacher}`;
  if (!Object.prototype.hasOwnProperty.call(state.teacherAllocations, teacherKey)) {
    Vue.set(state.teacherAllocations, teacherKey, {entries:[]});
  }
  state.teacherAllocations[teacherKey].entries.push(tosa);
  state.teacherAllocations[teacherKey].entries.sort(
    (a:any,b:any) => {
      const aValue = a.relClassSubjectNavigation?.classId ?? a.relProjectNavigation.id;
      const bValue = b.relClassSubjectNavigation?.classId ?? b.relProjectNavigation.id;
      return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
    }
  );
}
const teacherClassSubjectAllocationsDelete = (state:StateType, tosa:TeacherOnSubjectAllocationDto) => {
  const teacherKey = `${tosa.relTeacher}`;
  if (Object.prototype.hasOwnProperty.call(state.teacherAllocations, teacherKey)) {
    const index = state.teacherAllocations[teacherKey].entries.findIndex((q:TeacherOnSubjectAllocationDto) => q.relClassSubject === tosa.relClassSubject);
    if (index > -1) {
      state.teacherAllocations[teacherKey].entries.splice(index, 1);
      return;
    }
  }
}



const calcProjectAllocationStats = (state:StateType) => {
  Object.keys(state.projectAllocations).forEach(key => {
    state.projectAllocations[key].totalHours = 
      state.projectAllocations[key].entries.map((q: TeacherOnSubjectAllocationDto) => q.hours).reduce((previous:number, current:number) => previous + current, 0);
    state.projectAllocations[key].count = state.projectAllocations[key].entries.length;
  });
}

const calcClassSubjectAllocationStats = (state:StateType) => {
  Object.keys(state.classSubjectAllocations).forEach(key => {
    state.classSubjectAllocations[key].totalHours = 
      state.classSubjectAllocations[key].entries
        .filter((q:TeacherOnSubjectAllocationDto) => (q.relClassSubjectNavigation?.relMergedIntoClassSubject ?? null) === null)
        .map((q: TeacherOnSubjectAllocationDto) => q.hours).reduce((previous:number, current:number) => previous + current, 0)
    state.classSubjectAllocations[key].count = state.classSubjectAllocations[key].entries.length;
  });
}

const calcTeacherAllocationStats = (state:StateType) => {
  Object.keys(state.teacherAllocations).forEach(key => {
    state.teacherAllocations[key].totalHours = 
      state.teacherAllocations[key].entries
        .filter((q:any) => (q.relClassSubjectNavigation?.relMergedIntoClassSubject ?? null) === null)
        .map((q: TeacherOnSubjectAllocationDto|TeacherOnProjectAllocationDto) => q.hours).reduce((previous:number, current:number) => previous + current, 0)
    state.teacherAllocations[key].count = state.teacherAllocations[key].entries.length;
  });
}

export {
  AllocationsType,
  StateType,
  dataupdateTeacherOnSubjectAllocation,
  dataupdateTeacherOnProjectAllocation,
  findTeacherOnSubjectAllocation,
  findTeacherOnProjectAllocation,
  removeTeacherOnSubjectAllocation,
  removeTeacherOnProjectAllocation,
  projectAllocationsUpdate,
  projectAllocationsAdd,
  projectAllocationsDelete,
  teacherProjectAllocationsUpdate,
  teacherProjectAllocationsAdd,
  teacherProjectAllocationsDelete,
  classSubjectAllocationsUpdate,
  classSubjectAllocationsAdd,
  classSubjectAllocationsDelete,
  teacherClassSubjectAllocationsUpdate,
  teacherClassSubjectAllocationsAdd,
  teacherClassSubjectAllocationsDelete,
  calcProjectAllocationStats,
  calcClassSubjectAllocationStats,
  calcTeacherAllocationStats
}
