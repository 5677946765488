export const oidcRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../../views/oidc/Login.vue'),
    meta: {
      isPublic: true
    }
  },
  {
    path: '/loginerr',
    component: () => import('@/views/oidc/LoginErr.vue'),
    meta: { isPublic: true }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../../views/oidc/Logout.vue'),
    meta: { isPublic: true }
  },
  {
    path: '/oidc-callback', // Needs to match redirectUrl in oidcSettings
    name: 'oidcCallback',
    component: () => import('../../views/oidc/OidcCallback.vue'),
    meta: {
      isPublic: true
    }
  },
  {
    path: '/oidc-popup-callback', // Needs to match popupRedirectUrl in oidcSettings
    name: 'oidcPopupCallback',
    component: () => import('../../views/oidc/OidcPopupCallback.vue'),
    meta: {
      isPublic: true
    }
  },
  {
    path: '/oidc-callback-error', // Needs to match redirect_uri in oidcSettings
    name: 'oidcCallbackError',
    component: () => import('../../views/oidc/OidcCallbackError.vue'),
    meta: {
      isPublic: true
    }
  },
]
