import PlanningClient from '@/clients/PlanningClient';
import { ClassSubjectDto } from '@/models/planning/ClassSubjectDto';
import { ClassSubjectDtoResult } from '@/models/planning/ClassSubjectDtoResult';
import { Guid } from 'guid-typescript';
import { IClassSubjectRepository } from '../interfaces/planning/IClassSubjectRepository';
import qs from 'qs';

const resource = '/classSubject';

export const ClassSubjectRepository: IClassSubjectRepository = {
  getFiltered: (departments:number[]|undefined, schoolTerms:string[]|undefined, schoolSubjects:number[]|undefined, showDeactivated:boolean|undefined, skip:number|undefined, take:number|undefined) =>
    PlanningClient.get(
      `${resource}/filtered`, {
        params: {
          departments: departments,
          schoolTerms: schoolTerms,
          schoolSubjects: schoolSubjects,
          showDeactivated: showDeactivated,
          skip: skip ?? '',
          take: take ?? ''
        },
        paramsSerializer: params => qs.stringify(params), // to format array parameters in a way that .net core will pick up by default

    }) as Promise<{data:ClassSubjectDtoResult}>,

  getOne: (classSubjectId:Guid) => 
    PlanningClient.get(`${resource}/${classSubjectId}`) as Promise<{data:ClassSubjectDto}>,

  create: (formClassSubject:ClassSubjectDto) =>
    PlanningClient.post(`${resource}`, formClassSubject) as Promise<{data:ClassSubjectDto}>,
  
  update: (formClassSubject:ClassSubjectDto) =>
    PlanningClient.put(`${resource}`, formClassSubject) as Promise<{data:ClassSubjectDto}>,
  
  deactivate: (classSubjectId:Guid) =>
    PlanningClient.put(`${resource}/deactivate/${classSubjectId}`),

  activate: (classSubjectId:Guid) =>
    PlanningClient.put(`${resource}/activate/${classSubjectId}`),
  
  isDeletable: (classSubjectId:Guid) =>
    PlanningClient.get(`${resource}/deletable/${classSubjectId}`) as Promise<{data:boolean}>,
  
  remove: (classSubjectId:Guid) =>
    PlanningClient.delete(`${resource}/${classSubjectId}`),
  
}
