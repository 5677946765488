import PlanningClient from '@/clients/PlanningClient';
import { SchoolSubjectDto } from '@/models/planning/SchoolSubjectDto';
import { TeacherTermDto } from '@/models/planning/TeacherTermDto';
import { Guid } from 'guid-typescript';

const resource = '/SchoolSubject';

export const SchoolSubjectRepository = {
  async getAll() {
    return await PlanningClient.get<SchoolSubjectDto>(`${resource}`);
  },
}
