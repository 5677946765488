import { ProjectsDto } from "../planning/ProjectsDto";

type ProjectCriteriaModel = {
  departments?: number[] | undefined;
  projectTypes?: number[] | undefined;
  financedOption?: boolean | undefined;
}

const defaultProjectCriteriaModel = {
  departments: undefined,
  projectTypes: undefined,
  financedOption: undefined
} as ProjectCriteriaModel

const projectFilter = (collection:ProjectsDto[], criteriaModel:ProjectCriteriaModel) =>
  collection.filter((q: ProjectsDto) => {
    let keep = true;
    if (criteriaModel.departments) {
      keep &&= criteriaModel.departments.includes(q.relDepartment);
    }
    if (criteriaModel.projectTypes) {
      keep &&= criteriaModel.projectTypes.includes(q.relProjectType);
    }
    if (criteriaModel.financedOption !== undefined) {
      keep &&= criteriaModel.financedOption === q.isFinanced;
    }
    return keep;
  });

export {
  ProjectCriteriaModel,
  projectFilter,
  defaultProjectCriteriaModel
}
