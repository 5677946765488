import type { GetterTree, MutationTree, ActionTree } from 'vuex';
import loadingState from '@/enums/APISTATE';
import { Guid } from 'guid-typescript';
import { NumberPerStudentTypeReportedDto } from '@/models/planning/NumberPerStudentTypeReportedDto';
import { ClassSubjectStudentTypeRepository } from '@/repositories/planning/ClassSubjectStudentTypeRepository';
import { ClassSubjectFilter } from '@/models/Filters/ClassSubjectFilter';


class State {
  apiState = loadingState.INIT;
  numberPerStudentTypeReported: NumberPerStudentTypeReportedDto | undefined = undefined;
  numberPerStudentTypeReporteds: NumberPerStudentTypeReportedDto[] = [];
}

const mutations = <MutationTree<State>> {
  setApiState(state, apiState: number) {
    state.apiState = apiState;
  },
  setNumberPerStudentTypeReported(state, numberPerStudentTypeReported: NumberPerStudentTypeReportedDto) {
    state.numberPerStudentTypeReported = numberPerStudentTypeReported;
  },
  setNumberPerStudentTypeReporteds(state, numberPerStudentTypeReporteds: NumberPerStudentTypeReportedDto[]) {
    state.numberPerStudentTypeReporteds = numberPerStudentTypeReporteds;
  },

}

const actions = <ActionTree<State, any>> {
  async loadNumberPerStudentTypeReported({commit}, {relClassSubject, relStudentType}) {
    commit('apiState', loadingState.LOADING);
    try {
      const { data } = await ClassSubjectStudentTypeRepository.getOne(relClassSubject, relStudentType);
      commit('setNumberPerStudentTypeReported', data);
      commit('setApiState', loadingState.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', loadingState.ERROR);
    }
  },

  async loadNumberPerStudentTypeReporteds({commit}, relClassSubject:Guid) {
    commit('setApiState', loadingState.LOADING);
    try {
      const { data } = await ClassSubjectStudentTypeRepository.getAllForClassSubject(relClassSubject);
      commit('setNumberPerStudentTypeReporteds', data);
      commit('setApiState', loadingState.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', loadingState.ERROR);
    }
  },
  async loadFiltered({commit}, filter: ClassSubjectFilter) {
    commit('setApiState', loadingState.LOADING);
    try {
      const { data } = await ClassSubjectStudentTypeRepository.getFiltered(filter);
      commit('setNumberPerStudentTypeReporteds', data.data);
      commit('setApiState', loadingState.LOADED);

      const { skipped, taken, totalRecords } = data;
      return { skipped, taken, totalRecords };
    }
    catch (error) {
      console.error(error);
      commit('setApiState', loadingState.ERROR);
      return null;
    }
  },
  async loadByTerm({ commit, dispatch }, schoolTerm: string) {
    await dispatch("loadFiltered", {
      schoolTerm,
    });
  },
  async update({commit}, numberPerStudentTypeReportedDto: NumberPerStudentTypeReportedDto) {
    commit('setApiState', loadingState.LOADING);
    try {
      await ClassSubjectStudentTypeRepository.update(numberPerStudentTypeReportedDto);
      commit('setApiState', loadingState.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', loadingState.ERROR);
      throw error;
    }
  },

  prepareNewNumberPerStudentTypeReported({commit}, relClassSubject:Guid) {
    commit('setNumberPerStudentTypeReported', { relClassSubject });
  },
  clearNumberPerStudentTypeReported({commit}) {
    commit('setNumberPerStudentTypeReported', undefined);
  },

  async createNumberPerStudentTypeReported({commit}, formNumberPerStudentTypeReportedDto:NumberPerStudentTypeReportedDto) {
    commit('setApiState', loadingState.LOADING);
    try {
      const { data } = await ClassSubjectStudentTypeRepository.create(formNumberPerStudentTypeReportedDto);
      commit('setNumberPerStudentTypeReported', data);
      commit('setApiState', loadingState.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', loadingState.ERROR);
      throw error;
    }
  },

  async removeNumberPerStudentTypeReported({commit, dispatch}, {relClassSubject, relStudentType}) {
    commit('setApiState', loadingState.LOADING);
    try {
      await ClassSubjectStudentTypeRepository.remove(relClassSubject, relStudentType);
      await dispatch('loadNumberPerStudentTypeReporteds', relClassSubject);
      commit('setApiState', loadingState.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', loadingState.ERROR);
      throw error;
    }
  }
}

const classIdSort = (a:NumberPerStudentTypeReportedDto,b:NumberPerStudentTypeReportedDto) => {
  const aValue = `${a.relClassSubjectNavigation?.classId}`.toUpperCase();
  const bValue = `${b.relClassSubjectNavigation?.classId}`.toUpperCase();
  return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
}

const getters = <GetterTree<State, any>> {
  numberPerStudentTypeReporteds: state => state.numberPerStudentTypeReporteds.sort(classIdSort),
  isLoading: state => state.apiState === loadingState.LOADING,
  classSubjectStudentTypeApiState: state => state.apiState,
  numberPerStudentTypeReported: state => state.numberPerStudentTypeReported,

}

const ClassSubjectStudentTypeStore = {
  namespaced: true,
  state: new State(),
  mutations,
  actions,
  getters
};

export default ClassSubjectStudentTypeStore;
