import PlanningClient from '@/clients/PlanningClient';
import { IClassSubjectStudentTypeRepository } from '../interfaces/planning/IClassSubjectStudentTypeRepository';
import { Guid } from "guid-typescript";
import { NumberPerStudentTypeReportedDto } from '@/models/planning/NumberPerStudentTypeReportedDto';
import { ClassSubjectFilter } from '@/models/Filters/ClassSubjectFilter';
import { FilteredResponse } from '@/models/Filters/FilteredResponse';
import qs from 'qs';

const resource = '/ClassSubjectStudentType'

export const ClassSubjectStudentTypeRepository: IClassSubjectStudentTypeRepository = {
  getAllForClassSubject: (relClassSubject:Guid) =>
    PlanningClient.get(`${resource}/${relClassSubject}`) as Promise<{data:NumberPerStudentTypeReportedDto[]}>,

  getOne: (relClassSubject:Guid, relStudentType:number) =>
    PlanningClient.get(`${resource}/${relClassSubject}/${relStudentType}`) as Promise<{data:NumberPerStudentTypeReportedDto}>,

  create: (formNumberPerStudentTypeReportedDto: NumberPerStudentTypeReportedDto) =>
    PlanningClient.post(`${resource}`, formNumberPerStudentTypeReportedDto) as Promise<{data:NumberPerStudentTypeReportedDto}>,

  remove: (relClassSubject:Guid, relStudentType:number) =>
    PlanningClient.delete(`${resource}/${relClassSubject}/${relStudentType}`),

  getFiltered: (filter: ClassSubjectFilter) =>
    PlanningClient.get(
      `${resource}/filtered`, 
      { 
        params: filter,
        paramsSerializer: params => qs.stringify(params),
      }
    ) as Promise<{data: FilteredResponse<NumberPerStudentTypeReportedDto>}>,

  update: (numberPerStudentTypeReportedDto: NumberPerStudentTypeReportedDto) =>
    PlanningClient.put(`${resource}`, numberPerStudentTypeReportedDto) as Promise<{data: NumberPerStudentTypeReportedDto}>,
}

