import type { GetterTree, MutationTree, ActionTree } from 'vuex';
import loadingState from '@/enums/APISTATE';
import { TeacherTermDto } from '@/models/planning/TeacherTermDto';
import { TeacherTermsRepository } from '@/repositories/planning/TeacherTermsRepository';
import { Guid } from 'guid-typescript';

const bySchoolTerm = (a:TeacherTermDto, b:TeacherTermDto) => {
  const aValue = `${a.relSchoolTerm}`.toUpperCase();
  const bValue = `${b.relSchoolTerm}`.toUpperCase();
  return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
}

class State {
  teacherTerms: TeacherTermDto[] = [];
  loadingState: number = loadingState.INIT;
}

const mutations = <MutationTree<State>> {
  setTeacherTerms(state, teacherTerms) {
    state.teacherTerms = teacherTerms;
  },
  setState(state, loadingState) {
    state.loadingState = loadingState;
  },
  addTerm(state, term) {
    state.teacherTerms.push(term);
  },
  updateTerm(state, teacherTerm:TeacherTermDto) {
    const existing = state.teacherTerms.find(q => q.relTeacher == teacherTerm.relTeacher && q.relSchoolTerm == teacherTerm.relSchoolTerm);
    if (existing) {
      existing.relEmploymentType = teacherTerm.relEmploymentType;
      existing.relEmploymentTypeNavigation = teacherTerm.relEmploymentTypeNavigation;
      existing.relEducationalDirection = teacherTerm.relEducationalDirection;
      existing.relEducationalDirectionNavigation = teacherTerm.relEducationalDirectionNavigation;
      existing.employmentFactor = teacherTerm.employmentFactor;
      existing.teachingFactor = teacherTerm.teachingFactor;
      existing.comment = teacherTerm.comment;
      existing.relDepartment = teacherTerm.relDepartment;
      existing.relDepartmentNavigation = teacherTerm.relDepartmentNavigation;
    }
  }
};

const actions = <ActionTree<State, any>> {
  async getAllForTeacher({ commit }, id: Guid) {
    try {
      commit("setState", loadingState.LOADING);
      const { data } = await TeacherTermsRepository.getForTeacher(id);
      commit("setTeacherTerms", data);
      commit("setState", loadingState.LOADED);
    } catch {
      commit("setState", loadingState.ERROR);
    }
  },

  async loadAllTeacherTerms({commit}) {
    commit("setState", loadingState.LOADING);
    try {
      const { data } = await TeacherTermsRepository.getAll();
      commit("setTeacherTerms", data);
      commit("setState", loadingState.LOADED);
    }
    catch (error) {
      console.error(error);
      commit("setState", loadingState.ERROR);
    }
  },

  async loadTeacherTermsBySchoolTerm({commit}, schoolTermId:string) {
    commit("setState", loadingState.LOADING);
    try {
      const { data } = await TeacherTermsRepository.getForSchoolTerm(schoolTermId);
      commit("setTeacherTerms", data);
      commit("setState", loadingState.LOADED);
    }
    catch (error) {
      console.error(error);
      commit("setState", loadingState.ERROR);
    }
  },
  
  async saveTerms({ commit }, teacherTerm: TeacherTermDto) {
    try {
      commit("setState", loadingState.LOADING);
      teacherTerm.relTeacher = teacherTerm.relTeacherNavigation.id;
      teacherTerm.relSchoolTerm = teacherTerm.relSchoolTermNavigation.id;
      teacherTerm.relEmploymentType = teacherTerm.relEmploymentTypeNavigation.id;
      teacherTerm.relEducationalDirection = teacherTerm.relEducationalDirectionNavigation.id;
      teacherTerm.relDepartment = teacherTerm.relDepartmentNavigation.id;
      
      const { data } = await TeacherTermsRepository.post(teacherTerm);
      commit("addTerm", data);
      commit("setState", loadingState.LOADED);
    } catch {
      commit("setState", loadingState.ERROR);
    }
  },

  async updateTerms({commit}, teacherTerm:TeacherTermDto) {
    commit('setState', loadingState.LOADING);
    try {
      const {data} = await TeacherTermsRepository.put(teacherTerm);
      commit('updateTerm', data);
      commit('setState', loadingState.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setState', loadingState.ERROR);
      throw error;
    }
  },

  async removeTeacherTerm({commit,dispatch}, teacherTerm:TeacherTermDto) {
    commit('setState', loadingState.LOADING);
    try {
      await TeacherTermsRepository.remove(teacherTerm);
      await dispatch('getAllForTeacher', teacherTerm.relTeacher);
      commit('setState', loadingState.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setState', loadingState.ERROR);
      throw error;
    }
  }
};

const getters = <GetterTree<State, any>> {
  teacherTerms: state => state.teacherTerms.sort(bySchoolTerm),
  isLoading: state => state.loadingState == loadingState.LOADING,
  teacherTermsForSchoolTerm: state => (teacherId:Guid) => state.teacherTerms.filter((q:TeacherTermDto) => q.relTeacher === teacherId).sort(bySchoolTerm),
  teacherHoursForPlanning: (state,getters,rootState,rootGetters) => (teacherId:Guid) => {
    // To use this method, teacherTerms must be loaded by SchoolTerm id (loadTeacherTermsBySchoolTerm)
    const terms = getters.teacherTermsForSchoolTerm(teacherId);
    if (terms.length === 1 && terms[0].relSchoolTermNavigation) {
      const isGL = terms[0].relEducationalDirectionNavigation.title === 'GL';
      const teachingFactor = terms[0].teachingFactor;
      const teachingNormHours = (isGL) ? terms[0].relSchoolTermNavigation.teacingNormHoursHf : terms[0].relSchoolTermNavigation.teacingNormHoursOther;
      return Math.round(teachingNormHours * teachingFactor / 100);
    }
    return 0;
  },
};

const TeacherTermsStore = {
  namespaced: true,
  state: new State(),
  mutations,
  actions,
  getters
};

export default TeacherTermsStore;
