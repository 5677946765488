import type { GetterTree, MutationTree, ActionTree } from 'vuex';
import APISTATE from '@/enums/APISTATE';
import { TeacherSubjectDto } from '@/models/planning/TeacherSubjectDto';
import { TeacherSubjectRepository } from '@/repositories/planning/TeacherSubjectRepository';
import { Guid } from 'guid-typescript';
import Vue from 'vue';

const byEduDirTitleThenSubject = (a:TeacherSubjectDto, b:TeacherSubjectDto) => {
  const aValue = `${a.relEducationalDirectionNavigation?.title}`.toUpperCase();
  const bValue = `${b.relEducationalDirectionNavigation?.title}`.toUpperCase();
  const aaValue = `${a.relSchoolSubjectNavigation.title}`.toUpperCase();
  const bbValue = `${b.relSchoolSubjectNavigation.title}`.toUpperCase();
  return aValue > bValue ? 1 : aValue < bValue ? -1 : aaValue > bbValue ? 1 : aaValue < bbValue ? -1 : 0;
}

class State {
  apiState: Number = APISTATE.INIT;
  teacherSubject: TeacherSubjectDto | {} = {};
  teacherSubjects: TeacherSubjectDto[] | [] = [];
}

const mutations = <MutationTree<State>> {
  setApiState(state, apiState: Number) {
    state.apiState = apiState;
  },
  setTeacherSubject(state, teacherSubject: TeacherSubjectDto) {
    Vue.set(state, 'teacherSubject', teacherSubject);
    // state.teacherSubject = teacherSubject;
  },
  setTeacherSubjects(state, teacherSubjects: TeacherSubjectDto[]) {
    state.teacherSubjects = teacherSubjects;
  },

}

const actions = <ActionTree<State, any>> {
  async loadTeacherSubject({commit}, teacherId: Guid) {
    commit('setApiState', APISTATE.LOADING);
    try {
      const { data } = await TeacherSubjectRepository.getOne(teacherId);
      commit('setTeacherSubject', data);
      commit('setApiState', APISTATE.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
    }
  },
  async loadTeacherSubjects({commit}, teacherId:Guid) {
    commit('setApiState', APISTATE.LOADING);
    try {
      const { data } = await TeacherSubjectRepository.getAllForTeacher(teacherId);
      commit('setTeacherSubjects', data);
      commit('setApiState', APISTATE.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
    }
  },
  async loadAllTeacherSubjects({commit}) {
    commit('setApiState', APISTATE.LOADING);
    try {
      const { data } = await TeacherSubjectRepository.getAll();
      commit('setTeacherSubjects', data);
      commit('setApiState', APISTATE.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
    }
  },
  prepareNewTeacherSubject({commit}, teacherId:Guid) {
    commit('setTeacherSubject', { relTeacher: teacherId });
  },
  async createTeacherSubject({commit, dispatch}, teacherSubject:TeacherSubjectDto) {
    commit('setApiState', APISTATE.LOADING);
    try {
      const { data } = await TeacherSubjectRepository.create(teacherSubject);
      commit('setTeacherSubject', data);
      await dispatch('loadTeacherSubjects', data.relTeacher);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
      throw error;
    }
  },
  async removeTeacherSubject({commit,dispatch}, {relTeacher,relSchoolSubject,relEducationalDirection}) {
    commit('setApiState', APISTATE.LOADING);
    try {
      await TeacherSubjectRepository.remove(relTeacher,relSchoolSubject,relEducationalDirection);
      await dispatch('loadTeacherSubjects', relTeacher);
      commit('setApiState', APISTATE.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
      throw error;
    }
  }
}

const getters = <GetterTree<State, any>> {
  teacherSubjectApiState: state => state.apiState,
  teacherSubjects: state => state.teacherSubjects.sort(byEduDirTitleThenSubject),
  teacherSubject: state => state.teacherSubject,

}

const TeacherSubjectsStore = {
  namespaced: true,
  state: new State(),
  mutations,
  actions,
  getters
};

export default TeacherSubjectsStore;
