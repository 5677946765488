import type { GetterTree, MutationTree, ActionTree } from 'vuex';
import type { SchoolTermDto } from '@/models/planning/SchoolTermDto';
import APISTATE from '@/enums/APISTATE';
import { SchoolTermRepository } from "@/repositories/planning/SchoolTermRepository";
import { SchoolTermDeleteCheckDto } from '@/models/planning/SchoolTermDeleteCheckDto';

const byId = (a: SchoolTermDto, b: SchoolTermDto) => {
  const aValue = `${a.id}`.toUpperCase();
  const bValue = `${b.id}`.toUpperCase();
  return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
};

class State {
  apiState: Number = APISTATE.INIT;
  schoolTerms: SchoolTermDto[] = [];
  schoolTerm: SchoolTermDto | {} = {};
  schoolTermDeleteCheck: SchoolTermDeleteCheckDto | {} = {};
  schoolTermDeleteCheckApiState: Number = APISTATE.INIT;
}

const mutations = <MutationTree<State>>{
  setApiState(state, apiState: Number) {
    state.apiState = apiState;
  },
  setSchoolTerm(state, schoolTerm: SchoolTermDto) {
    state.schoolTerm = schoolTerm;
  },
  setSchoolTerms(state, schoolTerms: SchoolTermDto[]) {
    state.schoolTerms = schoolTerms;
  },
  setSchoolTermDeleteCheck(state, schoolTermDeleteCheck) {
    state.schoolTermDeleteCheck = schoolTermDeleteCheck;
  },
  setSchoolTermDeleteCheckApiState(state, apiState) {
    state.schoolTermDeleteCheckApiState = apiState;
  }
};

const actions = <ActionTree<State, any>>{
  async loadSchoolTerm({ commit }, schoolTermId: string) {
    commit('setApiState', APISTATE.LOADING);
    try {
      const { data } = await SchoolTermRepository.getOne(schoolTermId);
      commit('setSchoolTerm', data);
      commit('setApiState', APISTATE.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
    }
  },
  async loadSchoolTerms({ commit }) {
    commit('setApiState', APISTATE.LOADING);
    try {
      const { data } = await SchoolTermRepository.getAll();
      commit('setSchoolTerms', data);
      commit('setApiState', APISTATE.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
    }
  },
  async createSchoolTerm({ commit, dispatch }, formSchoolTerm) {
    commit('setApiState', APISTATE.LOADING);
    try {
      const { data } = await SchoolTermRepository.create(formSchoolTerm);
      await dispatch('loadSchoolTerm', data.id);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
      throw error;
    }
  },
  async updateSchoolTerm({ commit, dispatch }, formSchoolTerm) {
    commit('setApiState', APISTATE.LOADING);
    try {
      const { data } = await SchoolTermRepository.update(formSchoolTerm);
      await dispatch('loadSchoolTerm', data.id);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
      throw error;
    }
  },
  async removeSchoolTerm({ commit }, schoolTermId: string) {
    commit('setApiState', APISTATE.LOADING);
    try {
      await SchoolTermRepository.remove(schoolTermId);
      commit('setApiState', APISTATE.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
      throw error;
    }
  },
  async checkSchoolTermDelete({ commit }, schoolTermId: string) {
    commit('setSchoolTermDeleteCheck', {});
    commit('setSchoolTermDeleteCheckApiState', APISTATE.LOADING);
    try {
      const { data } = await SchoolTermRepository.checkDelete(schoolTermId);
      commit('setSchoolTermDeleteCheck', data);
      commit('setSchoolTermDeleteCheckApiState', APISTATE.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setSchoolTermDeleteCheckApiState', APISTATE.ERROR);
    }
  },
  async downloadExcel({ commit }, schoolTermId: string) {
    try {
      commit('setApiState', APISTATE.LOADING);
      const result = await SchoolTermRepository.downloadExcel(schoolTermId);
      commit('setApiState', APISTATE.LOADED);
      return result.data;
    } catch (e) {
      console.error(e);

      commit('setApiState', APISTATE.ERROR);
    }
  }
};

const getters = <GetterTree<State, any>>{
  schoolTermApiState: state => state.apiState,
  schoolTerms: state => state.schoolTerms.sort(byId),
  schoolTerm: state => state.schoolTerm,
  schoolTermDeleteCheck: state => state.schoolTermDeleteCheck,
  schoolTermDeleteCheckApiState: state => state.schoolTermDeleteCheckApiState,

};

const SchoolTermStore = {
  namespaced: true,
  state: new State(),
  mutations,
  actions,
  getters
};

export default SchoolTermStore;
