import PlanningClient from '@/clients/PlanningClient';
import { EnumDto } from '@/models/EnumDto';
import { AxiosResponse } from 'axios';

const resource = '/enum';

export default {
  getSchoolTermStatusTypes: (): Promise<{data:EnumDto[]}> => PlanningClient.get(`${resource}/schoolTermStatusTypes`),
  getIncludeInAnnualManhours: ():Promise<{data:EnumDto[]}> => PlanningClient.get(`${resource}/includeInAnnualManhours`),

}
