<template>
  <header class="app-header">
    <div class="container is-fluid" v-if="oidcIsAuthenticated">
      <div class="container header__navbar">
        <div class="header__navbar__menu-trigger" @click="openSidebarMenu">
          <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="26"
               viewBox="0 0 283.426 283.426" style="enable-background:new 0 0 283.426 283.426;" xml:space="preserve">
            <g>
              <rect x="0" y="20.84" width="283.426" height="27.735" />
              <rect x="0" y="117.282" width="283.426" height="27.735" />
              <rect x="0" y="214.851" width="283.426" height="27.735" />
            </g>
          </svg>
        </div>
        <div class="header__navbar__title">
          {{ this.pageTitle }}
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'app-header',
  computed: {
    ...mapGetters('oidcStore', ['oidcIsAuthenticated']),
    ...mapGetters(['pageTitle'])
  },
  methods: {
    ...mapActions('oidcStore', ['signOutOidc']),
    ...mapActions('sidebarMenuStore', ['openSidebarMenu'])
  }
}
</script>

<style lang="scss">
  .app-header {
    display: flex;
    flex-flow: column nowrap;
    background-color: $header-background-color;
    color: $header-color;

    .container.is-fluid {
      padding: 0;
    }
    .header__navbar {
      height: 60px;
      background-image: url(../assets/vucstorstroemlogo-noslogan.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 65px;
      display: flex;
      flex-flow: row nowrap;

      .header__navbar__menu-trigger {
        flex: 0 0 60px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        position: relative;
        left: -15px;
      }
      .header__navbar__title {
        flex: 1 1 auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        left: -10px;
      }
    }
  }
</style>
