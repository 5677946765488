import type { GetterTree, MutationTree, ActionTree } from 'vuex';
import { ProjectTypeDto } from '@/models/planning/ProjectTypeDto';
import APISTATE from '@/enums/APISTATE';
import { ProjectTypeRepository } from "@/repositories/planning/ProjectTypeRepository";

const byTitle = (a:ProjectTypeDto, b:ProjectTypeDto) => {
  const aValue = `${a.title}`.toUpperCase();
  const bValue = `${b.title}`.toUpperCase();
  return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
};

class State {
  apiState: number = APISTATE.INIT;
  projectTypes: ProjectTypeDto[] = [];
}

const mutations = <MutationTree<State>> {
  setApiState(state, apiState: number) {
    state.apiState = apiState;
  },
  setProjectTypes(state, projectTypes: ProjectTypeDto[]) {
    state.projectTypes = projectTypes;
  },

};

const actions = <ActionTree<State, any>> {
  async loadProjectTypes({commit, state}) {
    commit('setApiState', APISTATE.LOADING);
    try {
      const { data } = await ProjectTypeRepository.getAll();
      commit('setProjectTypes', data);
      commit('setApiState', APISTATE.LOADED);
    }
    catch (error) {
      console.error(error);
      commit('setApiState', APISTATE.ERROR);
    }
  },

};

const getters = <GetterTree<State, any>> {
  projectTypesApiState: state => state.apiState,
  projectTypes: state => state.projectTypes.sort(byTitle),

};

const ProjectsStore = {
  namespaced: true,
  state: new State(),
  mutations,
  actions,
  getters
};

export default ProjectsStore;
