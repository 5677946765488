import PlanningClient from '@/clients/PlanningClient';
import { ProjectsDto } from '@/models/planning/ProjectsDto';
import { ProjectsDtoResult } from '@/models/planning/ProjectsDtoResult';
import { Guid } from 'guid-typescript';
import { IProjectsRepository } from '../interfaces/planning/IProjectsRepository';

const resource = '/projects';

export const ProjectsRepository: IProjectsRepository = {
  getFiltered: (department:number|undefined, schoolTerm:string|undefined, skip:number|undefined, take:number|undefined) => 
    PlanningClient.get(
      `${resource}/filtered`, {
        params: {
          department: department ?? '',
          schoolTerm: schoolTerm ?? '',
          skip: skip ?? '',
          take: take ?? ''
        }
      }) as Promise<{ data:ProjectsDtoResult }>,

  getById: (id:Guid) =>
    PlanningClient.get(`${resource}/${id}`) as Promise<{ data:ProjectsDto }>,

  create: (formProject:ProjectsDto) =>
    PlanningClient.post(`${resource}`, formProject) as Promise<{data:ProjectsDto}>,

  update: (formProject:ProjectsDto) =>
    PlanningClient.put(`${resource}`, formProject) as Promise<{data:ProjectsDto}>,

  remove: (id:Guid) =>
    PlanningClient.delete(`${resource}/${id}`),
    
}
