import Vue from 'vue'
import VueRouter from 'vue-router'
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'
import store from '@/store';
import { oidcRoutes } from './groups/oidc';
import { classSubjects } from './groups/ClassSubjects';
import { distribution } from './groups/Distribution';
import { projects } from './groups/Projects';
import { schoolTerms } from './groups/SchoolTerms';

Vue.use(VueRouter)

const routes = [
  ...classSubjects,
  ...distribution,
  ...oidcRoutes,
  ...projects,
  ...schoolTerms,
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/dashboard',
    component: () => import('../views/dashboard/Dashboard.vue'),
    children: [
      {
        path: '',
        name: 'Holdgennemsnit',
        component: () => import('../views/dashboard/tabs/Holdgennemsnit.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('dashboardStore/setTabGroupOptionKey', 'ClassSubjectAverage');
          next();
        },
      },
      {
        path: 'undervisningstimer',
        name: 'Undervisningstimer',
        component: () => import('../views/dashboard/tabs/Undervisningstimer.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('dashboardStore/setTabGroupOptionKey', 'LessonHoursAverage');
          next();
        },
      },
      {
        path: 'aarselever',
        name: 'Årselever',
        component: () => import('../views/dashboard/tabs/Aarselever.vue'),
        beforeEnter: (to, from, next) => {
          store.dispatch('dashboardStore/setTabGroupOptionKey', 'AnnualManhoursAverage');
          next();
        },
      }
    ]
  },
  {
    path: '/users/:userId/edit',
    name: 'UserEdit',
    props: true,
    component: () => import('../views/User.vue')
  },
  {
    path: '/users/:userId/password',
    name: 'UserPassword',
    props: true,
    component: () => import('../views/ChangePassword.vue')
  },
  {
    path: '/users/new',
    name: 'UserNew',
    component: () => import('../views/User.vue')
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/Users.vue')
  },
  {
    path: '/user/password',
    name: 'ChangePassword',
    component: () => import('../views/ChangePassword.vue')
  },
  {
    path: '/resetpassword',
    name: 'ResetPassword',
    component: () => import('../views/PasswordReset.vue'),
    meta: {
      isPublic: true
    }
  },
  {
    path: '/teachers',
    component: () => import('../views/Teachers/Teachers.vue'),
    beforeEnter: async (to, from, next) => {
      await Promise.all([
        store.dispatch("teachers/loadTeachers"),
        store.dispatch("schoolTermStore/loadSchoolTerms"),
        store.dispatch("departments/loadDepartments"),
        store.dispatch("employmentTypesStore/loadEmploymentTypes"),
        store.dispatch("educationDirectionsStore/loadEducationalDirections"),
        store.dispatch("schoolSubjectsStore/loadSchoolSubjects"),
      ])
      next();
    },
    children: [
      {
        path: '/',
        name: 'Teachers',
        component: () => import('../views/Teachers/TeachersOverview.vue'),
      },
      {
        path: ":id",
        component: () => import('../views/Teachers/Teacher.vue'),
        children: [
          {
            path: "",
            name: "Teacher",
            component: () => import("../views/Teachers/tabs/Info.vue")
          },
          {
            path: "terms",
            name: "Teacher terms",
            component: () => import("../views/Teachers/tabs/Terms.vue"),
          },
          {
            path: "subjects",
            name: "TeacherSubjects",
            component: () => import("../views/Teachers/tabs/Subjects.vue"),
          }
        ]
      }
      
    ]
  },
  {
    path: '/dev/components',
    name: 'DevComponents',
    component: () => import('../views/DevComponents.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'));

router.beforeEach((to, from, next) => {
  store.dispatch('validationStore/clearErrors');
  store.dispatch('sidebarMenuStore/sidebarMenuClosed');
  next();
});
export default router
