<template>
  <div id="app">
    <sidebar-menu></sidebar-menu>
    <div class="app-viewport">
      <app-header></app-header>
      <div class="app-contents">
        <div class="container">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import SidebarMenu from "./components/SidebarMenu.vue";

export default {
  name: "app",
  components: {
    AppHeader,
    SidebarMenu,
  },
};
</script>

<style lang="scss">
html,
body {
  height: 100%;
  background-color: $page-background-color;
}
html {
  overflow: hidden !important;
}

#app {
  font-family: Montserrat, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $page-color;
  height: 100vh;
  display: flex;

  .app-viewport {
    flex: 1;
    display: flex;
    flex-flow: column;

    .app-header {
      flex: 0;
    }
    .app-contents {
      flex: 2;
      overflow-y: scroll;
      width: 100%;
    }
  }
}
</style>
