import PlanningClient from '@/clients/PlanningClient';
import { ITeacherOnProjectAllocationRepository } from "../interfaces/planning/ITeacherOnProjectAllocationRepository";
import { TeacherOnProjectAllocationDtoResult } from "@/models/planning/TeacherOnProjectAllocationDtoResult";
import { TeacherOnProjectAllocationDto } from '@/models/planning/TeacherOnProjectAllocationDto';
import { Guid } from 'guid-typescript';
import qs from 'qs';

const resource = '/teacherOnProjectAllocation';

export const TeacherOnProjectAllocationRepository : ITeacherOnProjectAllocationRepository = {
  getFiltered: (schoolTerm:string, departments:number[]|undefined, projectTypes:number[]|undefined, financed:boolean[]|undefined, skip:number|undefined, take:number|undefined) =>
    PlanningClient.get(`${resource}/filtered/${schoolTerm}`, {
      params: {
        departments: departments,
        projectTypes: projectTypes,
        financed: financed,
        skip: skip ?? '',
        take: take ?? ''
      },
      paramsSerializer: params => qs.stringify(params), // to format array parameters in a way that .net core will pick up by default
    }) as Promise<{data:TeacherOnProjectAllocationDtoResult}>,

  create: (form:TeacherOnProjectAllocationDto) =>
    PlanningClient.post(`${resource}`, form) as Promise<{data:TeacherOnProjectAllocationDto}>,
  
  update: (form:TeacherOnProjectAllocationDto) =>
    PlanningClient.put(`${resource}`, form) as Promise<{data:TeacherOnProjectAllocationDto}>,
  
  remove: (relTeacher:Guid, relProject:Guid) =>
    PlanningClient.delete(`${resource}/${relTeacher}/${relProject}`),
  
}
